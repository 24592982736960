import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
  useRef
} from "react";
import styled from "styled-components";
import firebase from "../firebaseConfig";
import MapArea from "../components/MapArea/index";
import Sidebar from "../components/Sidebar/index";
import Geocode from "react-geocode";
import { UserContext } from "../context/user";
import { Redirect } from "react-router-dom";
import { v4 } from "uuid";
import { useMixPanel } from "../util/Mixpanel";
import { CustomerSubType, InfoGroup, Location, RowData } from "../types";
import { usePrevious } from "../hooks/usePrev";
import { isEqual } from "lodash";
import { db } from "../firebaseConfig";

const StyledMain = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: stretch;
`;

const getDistanceMatrix = (service: any, data: any) =>
  new Promise((resolve, reject) => {
    service.getDistanceMatrix(data, (response: any, status: any) => {
      if (status === "OK") {
        resolve(response);
      } else {
        reject(new Error("Not OK"));
      }
    });
  });

export enum Category {
  SETTLING = "settling",
  CUSTOMERS = "customers",
  COMPETITION = "competition"
}

const realtimeDb = firebase.database();
const ref = realtimeDb.ref("counter");

const projectName = process.env.REACT_APP_PROJECT_NAME || "";

function Main() {
  const [mapZoom, setMapZoom] = useState<number>(12);
  const [map, setMap] = useState<any>(null);
  const [maps, setMaps] = useState<any>(null);
  const [apiReady, setApiReady] = useState(false);
  const [inputQuery, setInputQuery] = useState("");
  const [activeCategory, setActiveCategory] = useState<string>(
    Category.CUSTOMERS
  );
  const [showCreationForm, setShowCreationForm] = useState(false);
  const [searchedLocation, setSearchedLocation] = useState<{
    location: {
      lat: number;
      lng: number;
    };
    formatted_address: string;
  } | null>(null);
  const [allLocations, setAllLocations] = useState<Location[]>([]);
  const [groupsToShow, setGroupsToShow] = useState<Category[]>([
    Category.COMPETITION,
    Category.CUSTOMERS,
    Category.SETTLING
  ]);
  const [infoGroups, setInfoGroups] = useState<InfoGroup[]>([]);
  const [distService, setDistService] = useState<any>(null);
  const [dirService, setDirService] = useState<any>(null);
  const [dirRenderer, setDirRenderer] = useState<any>(null);
  const [coordsToShowRoute, setCoordsToShowRoute] = useState<{
    origin: { lat: number; lng: number };
    destination: { lat: number; lng: number };
  } | null>(null);
  const [customerRadius, setCustomerRadius] = useState(30);
  const [settlingRadius, setSettlingRadius] = useState(30);
  const [editLocation, setEditLocation] = useState<{
    location: {
      lat: number;
      lng: number;
    };
    formatted_address: string;
  } | null>(null);
  const prevSearchedLocation = usePrevious(searchedLocation);
  const prevEditLocation = usePrevious(editLocation);
  const counter = useRef<number>(0);
  const user = useContext(UserContext);
  const { mp } = useMixPanel();
  const [redirect, setRedirect] = useState<string | null>(null);
  const [selectedCustomerId, setSelectedCustomerId] = useState("");
  const [tableView, setTableView] = useState<"full" | "reduced">("full");

  useEffect(() => {
    ref.on("value", snp => {
      console.log("changed");
      counter.current = snp.val();
    });
  }, []);

  useEffect(() => {
    if (!user) {
      setRedirect("/login");
    }
  }, [user]);

  useEffect(() => {
    if (!user) return;
    const fetchData = async () => {
      const docs: any[] = [];
      db.collection("locations")
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            docs.push({ ...doc.data(), id: doc.id, switched: true });
          });
        })
        .then(() => setAllLocations(docs));
    };
    fetchData();
  }, [user]);

  useEffect(() => {
    if (!apiReady) return;
    Geocode.setApiKey("AIzaSyAM8Ciwgzts5Dp9I8_DEYJN3oTWjAsz3rE");
    Geocode.setRegion("de");
    Geocode.enableDebug();
  }, [apiReady]);

  useEffect(() => {
    if (!maps) return;
    setDistService(new maps.DistanceMatrixService());
    setDirService(new maps.DirectionsService());
    setDirRenderer(new maps.DirectionsRenderer({ preserveViewport: true }));
  }, [maps]);

  const getShortRoute = async (request: any) => {
    const result: any = await new Promise((resolve, reject) => {
      dirService.route(request, (response: any, status: any) => {
        if (status === "OK") {
          resolve(response);
        } else reject(status);
      });
    });
    const shortestPath: string = result.routes
      .sort(
        (a: any, b: any) => a.legs[0].distance.value - b.legs[0].distance.value
      )[0]
      .legs[0].distance.text.split(" ")[0]
      .split(",")
      .join(".");
    return { result, shortestPath };
  };

  useEffect(() => {
    if (!coordsToShowRoute || !dirRenderer || !dirService || !map) return;
    dirRenderer.setMap(map);
    (async () => {
      const request = {
        origin: coordsToShowRoute.origin,
        destination: coordsToShowRoute.destination,
        travelMode: "DRIVING",
        provideRouteAlternatives: true
      };
      const { result, shortestPath } = await getShortRoute(request);
      let query = db
        .collection("distances")
        .where("origin.lat", "==", coordsToShowRoute.origin.lat);
      query = query.where("origin.lng", "==", coordsToShowRoute.origin.lng);
      query = query.where("dest.lat", "==", coordsToShowRoute.destination.lat);
      query = query.where("dest.lng", "==", coordsToShowRoute.destination.lng);
      const snapshot = await query.get();
      snapshot.forEach(async doc => {
        await doc.ref.update({ short_distance: shortestPath });
      });
      setInfoGroups(
        infoGroups.map(g => ({
          ...g,
          distances: g.distances.map(d =>
            g.group_title.location.lat === coordsToShowRoute.destination.lat &&
            g.group_title.location.lng === coordsToShowRoute.destination.lng &&
            d.location.lat === coordsToShowRoute.origin.lat &&
            d.location.lng === coordsToShowRoute.origin.lng
              ? { ...d, shortDistance: shortestPath }
              : d
          )
        }))
      );
      if (result) {
        dirRenderer.setDirections(result);
        const bounds = new maps.LatLngBounds();
        bounds.extend(
          new maps.LatLng(
            result.request.origin.location.lat(),
            result.request.origin.location.lng()
          )
        );
        bounds.extend(
          new maps.LatLng(
            result.request.destination.location.lat(),
            result.request.destination.location.lng()
          )
        );
        map.fitBounds(bounds);
        map.setZoom(map.getZoom() - 1);
      }
    })();
    return () => {
      dirRenderer.setMap(null);
    };
  }, [coordsToShowRoute, dirService, dirRenderer, map]);

  useEffect(() => {
    if (!searchedLocation || isEqual(searchedLocation, prevSearchedLocation))
      return;
    (async () => {
      try {
        const response = await Geocode.fromLatLng(
          searchedLocation.location.lat + "",
          searchedLocation.location.lng + ""
        );
        if (response.results.length === 0) {
          alert("Could not find any location");
          return;
        }
        const primaryLocation = response.results[0];

        setInputQuery(primaryLocation.formatted_address);
      } catch (error) {
        alert("Could not find any location");
        return;
      }
    })();
  }, [searchedLocation]);

  useEffect(() => {
    if (!editLocation || isEqual(editLocation, prevEditLocation)) return;
    (async () => {
      try {
        const response = await Geocode.fromLatLng(
          editLocation.location.lat + "",
          editLocation.location.lng + ""
        );
        if (response.results.length === 0) {
          alert("Could not find any location");
          return;
        }
        const primaryLocation = response.results[0];
        setEditLocation({
          ...editLocation,
          formatted_address: primaryLocation.formatted_address
        });
      } catch (error) {
        alert("Could not find any location");
        return;
      }
    })();
  }, [editLocation]);

  const getDistance = async (
    origin: { lat: number; lng: number; description: string },
    dest: { lat: number; lng: number; description: string }
  ) => {
    if (!user) return "0";
    try {
      if (counter.current > 5000) {
        console.warn("sorry, you cant make any more requests to some services");
      }
      const result: any = await getDistanceMatrix(distService, {
        origins: [origin],
        destinations: [dest],
        travelMode: maps.TravelMode.DRIVING,
        unitSystem: maps.UnitSystem.METRIC
      });
      mp.track(
        "API CALL",
        {
          Project_Name: projectName,
          Customer: dest.description,
          User: user.email,
          Api_Type: "google-distance_matrix",
          Date: new Date(),
          Month_Year: new Intl.DateTimeFormat("en-GB", {
            month: "numeric",
            year: "numeric"
          }).format(new Date()),
          Data_Source: "api"
        },
        async () => {
          console.log("api track saved");
          await ref.set(counter.current + 1, error => {
            if (error) console.error(error);
            else console.log("incremented counter");
          });
        }
      );
      await db.collection("distances").add({
        origin,
        dest,
        distance: (result.rows[0].elements[0].distance.value / 1000).toFixed(1)
      });
      console.log("distance cached", result);
      return (result.rows[0].elements[0].distance.value / 1000).toFixed(1);
    } catch (error) {
      console.error(error);
      return "0";
    }
  };

  const getShortDistance = async (
    origin: { lat: number; lng: number; description: string },
    dest: { lat: number; lng: number; description: string }
  ) => {
    let query = db
      .collection("distances")
      .where("origin.lat", "==", origin.lat);
    query = query.where("origin.lng", "==", origin.lng);
    query = query.where("dest.lat", "==", dest.lat);
    query = query.where("dest.lng", "==", dest.lng);
    const snapshot = await query.limit(1).get();
    return snapshot.empty
      ? null
      : snapshot.docs[0].data()["short_distance"]
      ? snapshot.docs[0].data()["short_distance"]
      : null;
  };

  useEffect(() => {
    if (
      distService &&
      selectedCustomerId &&
      allLocations.length &&
      allLocations.some(
        l => l.type.main === "customers" && l.id === selectedCustomerId
      )
    ) {
      console.log("fetch all distances");
      const fetchDistances = async () => {
        const customers = allLocations.filter(
          l => l.type.main === "customers" && l.id === selectedCustomerId
        );
        const groups: InfoGroup[] = await Promise.all(
          customers.map(async c => {
            const allDistancesOfCustomer = await db
              .collection("distances")
              .where("dest.description", "==", c.description)
              .get()
              .then(sp => {
                const data: any[] = [];
                sp.forEach(s => data.push(s.data()));
                return data;
              });
            return {
              group_title: {
                title: c.description,
                location: c.location,
                formatted_address: [
                  c.formatted_address.split(",")[0],
                  c.formatted_address.split(",")[1]
                ].join()
              },
              distances: await Promise.all(
                allLocations
                  .filter(
                    l => l.type.main !== "customers" && l.switched === true
                  )
                  .map(async l => ({
                    title: {
                      title: l.description,
                      formatted_address: [
                        l.formatted_address.split(",")[0],
                        l.formatted_address.split(",")[1]
                      ].join()
                    },
                    location: l.location,
                    distance: allDistancesOfCustomer.find(
                      d =>
                        d.dest.lng === c.location.lng &&
                        d.dest.lat === c.location.lat &&
                        d.origin.lng === l.location.lng &&
                        d.origin.lat === l.location.lat
                    )
                      ? allDistancesOfCustomer.find(
                          d =>
                            d.dest.lng === c.location.lng &&
                            d.dest.lat === c.location.lat &&
                            d.origin.lng === l.location.lng &&
                            d.origin.lat === l.location.lat
                        ).distance
                      : await getDistance(
                          { ...l.location, description: l.description },
                          {
                            ...c.location,
                            description: c.description
                          }
                        ),
                    shortDistance: allDistancesOfCustomer.find(
                      d =>
                        d.dest.lng === c.location.lng &&
                        d.dest.lat === c.location.lat &&
                        d.origin.lng === l.location.lng &&
                        d.origin.lat === l.location.lat
                    )
                      ? allDistancesOfCustomer.find(
                          d =>
                            d.dest.lng === c.location.lng &&
                            d.dest.lat === c.location.lat &&
                            d.origin.lng === l.location.lng &&
                            d.origin.lat === l.location.lat
                        ).short_distance
                      : await getShortDistance(
                          { ...l.location, description: l.description },
                          {
                            ...c.location,
                            description: c.description
                          }
                        ),
                    color: l.type.main === "settling" ? "#0275d8" : "#dc3545"
                  }))
              ),
              id: v4()
            };
          })
        );
        setInfoGroups(groups);
        await Promise.all(
          customers.map(async c => {
            const newShortestFiveDistances = await db
              .collection("distances")
              .where("dest.description", "==", c.description)
              .get()
              .then(sp => {
                const data: any[] = [];
                sp.forEach(s => data.push(s.data()));
                return data
                  .sort(
                    (a, b) =>
                      +(a.short_disatnce || a.distance) -
                      +(b.short_disatnce || b.distance)
                  )
                  .map(l => ({
                    location: { lat: l.origin.lat, lng: l.origin.lng },
                    type: allLocations.find(
                      loc =>
                        loc.description === l.origin.description &&
                        loc.location.lat === l.origin.lat &&
                        loc.location.lng === l.origin.lng
                    )?.type.main as Category.COMPETITION | Category.SETTLING,
                    description: l.origin.description,
                    distance: l.short_distance || l.distance
                  }))
                  .filter((_, idx) => idx < 5);
              });
            const subType = (c.type.sub as CustomerSubType) || {};
            await (await db.collection("locations").doc(c.id).get()).ref
              .update({
                type: {
                  ...c.type,
                  sub: {
                    ...subType,
                    nearest_neighbours: [...newShortestFiveDistances]
                  }
                }
              })
              .then(_ =>
                setAllLocations(
                  allLocations.map(l =>
                    l.id === c.id
                      ? {
                          ...l,
                          type: {
                            ...l.type,
                            sub: {
                              ...(l.type.sub as CustomerSubType),
                              nearest_neighbours: newShortestFiveDistances
                            }
                          }
                        }
                      : l
                  )
                )
              );
          })
        );
      };
      fetchDistances();
    } else {
      setInfoGroups([]);
    }
  }, [selectedCustomerId, distService]);

  useEffect(() => {
    if (!searchedLocation) return;
    setShowCreationForm(true);
  }, [searchedLocation]);

  const handleSetActiveCategory = useCallback(
    (category: Category) => {
      setActiveCategory(category);
    },
    [setActiveCategory]
  );

  const handleApiLoaded = useCallback((map: any, maps: any) => {
    if (map && maps) {
      setMaps(maps);
      setMap(map);
      setApiReady(true);
      // mp.track("API CALL", {
      //   User: user?.email,
      //   Api_Type: "google-maps",
      //   Date: new Date(),
      //   Month_Year: new Intl.DateTimeFormat("en-GB", {
      //     month: "numeric",
      //     year: "numeric"
      //   }).format(new Date()),
      //   Data_Source: "api"
      // });
    }
  }, []);

  const handleInputChange = useCallback(
    (value: string) => {
      setInputQuery(value);
    },
    [setInputQuery]
  );

  const handleDeleteLocation = async (id: string) => {
    const itemRef = await db.collection("locations").doc(id).get();
    const data = await itemRef.data();
    // mp.track("API CALL", {
    //   User: user?.email,
    //   Api_Type: "firebase-locations",
    //   Date: new Date(),
    //   Month_Year: new Intl.DateTimeFormat("en-GB", {
    //     month: "numeric",
    //     year: "numeric"
    //   }).format(new Date()),
    //   Data_Source: "api"
    // });
    const coords = data?.location;
    if (coords) {
      let query1 = db
        .collection("distances")
        .where("origin.lat", "==", coords.lat);
      query1 = query1.where("origin.lng", "==", coords.lng);
      let query2 = db
        .collection("distances")
        .where("dest.lat", "==", coords.lat);
      query2 = query2.where("dest.lng", "==", coords.lng);
      await Promise.all(
        [query1, query2].map(async query => {
          const snapshot = await query.get();
          if (!snapshot.empty) {
            snapshot.forEach(async doc => {
              await doc.ref.delete();
              console.log("doc deleted");
            });
          }
        })
      );
    }
    await db.collection("locations").doc(id).delete();
    if (user && data)
      mp.track("ACTION", {
        Project_Name: projectName,
        User: user.email,
        Action_Type: "delete",
        Customer: data.description,
        Customer_Type: data.type.main,
        Date: new Date(),
        Month_Year: new Intl.DateTimeFormat("en-GB", {
          month: "numeric",
          year: "numeric"
        }).format(new Date())
      });
    setAllLocations(prev => prev.filter(l => l.id !== id));
  };

  const handleSaveLocation = async (
    description: string,
    type: {
      main: string;
      sub?:
        | {
            title: string;
            color: string;
            __typename: Category.COMPETITION;
          }
        | CustomerSubType;
    }
  ) => {
    if (!searchedLocation) return;
    const newLocation = {
      ...searchedLocation,
      description,
      type,
      created_at: new Date().toISOString()
    };
    const savedLocation = await db.collection("locations").add(newLocation);
    if (user)
      mp.track("ACTION", {
        Project_Name: projectName,
        User: user.email,
        Action_Type: "save",
        Customer: newLocation.description,
        Customer_Type: newLocation.type.main,
        Date: new Date(),
        Month_Year: new Intl.DateTimeFormat("en-GB", {
          month: "numeric",
          year: "numeric"
        }).format(new Date())
      });

    const stateLocation = {
      ...newLocation,
      id: savedLocation.id,
      switched: true
    };

    setAllLocations([...allLocations, stateLocation]);
    setShowCreationForm(false);
    setSearchedLocation(null);
    setInputQuery("");
  };

  const cancelCreation = () => {
    setShowCreationForm(false);
    setSearchedLocation(null);
  };

  const handleChangeItem = async (
    itemId: string,
    newDesc: string,
    newType: {
      main: string;
      sub?:
        | {
            title: string;
            color: string;
          }
        | CustomerSubType;
    },
    newLocation?: Pick<Location, "location">,
    newFormattedAddress?: string
  ) => {
    try {
      const oldItem = allLocations.find(i => i.id === itemId);
      if (!oldItem) return;
      if (newType.sub && "title" in newType.sub) {
        await db
          .collection("locations")
          .where("type.sub", "==", newType.sub.title)
          .get()
          .then(snapshots => {
            if (snapshots.size > 0) {
              snapshots.forEach(item => {
                db.collection("locations")
                  .doc(item.id)
                  .update({ type: newType });
              });
            }
          });
        await db
          .collection("locations")
          .where("type.sub.title", "==", newType.sub.title)
          .get()
          .then(snapshots => {
            if (snapshots.size > 0) {
              snapshots.forEach(item => {
                db.collection("locations")
                  .doc(item.id)
                  .update({ type: newType });
              });
            }
          });
        if (user)
          mp.track("ACTION", {
            Project_Name: projectName,
            User: user.email,
            Action_Type: "edit",
            Customer: newDesc,
            Customer_Type: newType.main,
            Date: new Date(),
            Month_Year: new Intl.DateTimeFormat("en-GB", {
              month: "numeric",
              year: "numeric"
            }).format(new Date())
          });
      }

      if (newLocation && newFormattedAddress) {
        const { location: oldLocation } = oldItem;
        if (
          oldLocation.lat !== newLocation.location.lat ||
          oldLocation.lng !== newLocation.location.lng
        ) {
          let query_origin = db
            .collection("distances")
            .where("origin.lat", "==", oldLocation.lat);
          query_origin = query_origin.where(
            "origin.lng",
            "==",
            oldLocation.lng
          );
          let query_dest = db
            .collection("distances")
            .where("dest.lat", "==", oldLocation.lat);
          query_dest = query_dest.where("dest.lng", "==", oldLocation.lng);
          const snapshot_origin = await query_origin.get();
          const snapshot_dest = await query_dest.get();
          snapshot_origin.forEach(async doc => {
            const data = doc.data();
            const result: any = await getDistanceMatrix(distService, {
              origins: [newLocation.location],
              destinations: [data.dest],
              travelMode: maps.TravelMode.DRIVING,
              unitSystem: maps.UnitSystem.METRIC
            });
            const request = {
              origin: newLocation.location,
              destination: data.dest,
              travelMode: "DRIVING",
              provideRouteAlternatives: true
            };
            const { shortestPath } = await getShortRoute(request);
            await doc.ref.update({
              origin: {
                description: newDesc,
                lat: newLocation.location.lat,
                lng: newLocation.location.lng
              },
              distance: (
                result.rows[0].elements[0].distance.value / 1000
              ).toFixed(1),
              short_distance: shortestPath
            });
          });
          snapshot_dest.forEach(async doc => {
            const data = doc.data();
            const result: any = await getDistanceMatrix(distService, {
              origins: [data.origin],
              destinations: [newLocation.location],
              travelMode: maps.TravelMode.DRIVING,
              unitSystem: maps.UnitSystem.METRIC
            });
            await doc.ref.update({
              dest: {
                description: newDesc,
                lat: newLocation.location.lat,
                lng: newLocation.location.lng
              },
              distance: (
                result.rows[0].elements[0].distance.value / 1000
              ).toFixed(1)
            });
          });
        }
        if (oldItem.description !== newDesc) {
          const query_origin = db
            .collection("distances")
            .where("origin.description", "==", oldItem.description);
          const query_dest = db
            .collection("distances")
            .where("dest.description", "==", oldItem.description);
          const snapshot_origin = await query_origin.get();
          const snapshot_dest = await query_dest.get();
          const {
            location: { lat, lng }
          } = oldItem;
          snapshot_origin.forEach(async doc => {
            await doc.ref.update({
              origin: { description: newDesc, lat, lng }
            });
          });
          snapshot_dest.forEach(async doc => {
            await doc.ref.update({
              dest: { description: newDesc, lat, lng }
            });
          });
        }
      }

      await db
        .collection("locations")
        .doc(itemId)
        .update({
          description: newDesc,
          type: newType,
          location: { ...newLocation?.location },
          formatted_address: newFormattedAddress
        });
      const docs: any[] = [];
      db.collection("locations")
        .doc(itemId)
        .get()
        .then(doc => {
          docs.push({ ...doc.data(), id: doc.id, switched: true });
        })
        .then(() =>
          setAllLocations(prev =>
            prev.map(l => (l.id === docs[0].id ? docs[0] : l))
          )
        );
    } catch (error) {
      console.error(error);
    }
  };

  const handleSearchAddress = async () => {
    if (!inputQuery) return;
    try {
      const response = await Geocode.fromAddress(inputQuery);
      if (response.results.length === 0) {
        alert("Could not find any location");
        return;
      }
      const primaryLocation = response.results[0];
      setSearchedLocation({
        location: {
          ...primaryLocation.geometry.location
        },
        formatted_address: primaryLocation.formatted_address
      });
      setShowCreationForm(true);
    } catch (error) {
      alert("Could not find any location");
      return;
    } finally {
      if (!user) return;
      mp.track("API CALL", {
        Project_Name: projectName,
        User: user.email,
        Api_Type: "google-geocode",
        Date: new Date(),
        Month_Year: new Intl.DateTimeFormat("en-GB", {
          month: "numeric",
          year: "numeric"
        }).format(new Date()),
        Data_Source: "api"
      });
    }
  };

  const handleSetCoordsToShowRoute = (
    newCoords: {
      origin: {
        lat: number;
        lng: number;
      };
      destination: {
        lat: number;
        lng: number;
      };
    } | null
  ) => {
    if (coordsToShowRoute && newCoords) {
      const {
        origin: currOrigin,
        destination: currDestination
      } = coordsToShowRoute;
      if (
        currOrigin.lat === newCoords.origin.lat &&
        currOrigin.lng === newCoords.origin.lng &&
        currDestination.lat === newCoords.destination.lat &&
        currDestination.lng === newCoords.destination.lng
      ) {
        setCoordsToShowRoute(null);
        return;
      }
    }
    if (newCoords === null) {
      setCoordsToShowRoute(null);
      return;
    }
    setCoordsToShowRoute({
      origin: newCoords.origin,
      destination: newCoords.destination
    });
  };

  const handleCustomerTableRowClick = (rowData: RowData) => {
    if (map) {
      map.panTo(rowData.location);
    }
    if (selectedCustomerId === (rowData.customer_id as string)) {
      setSelectedCustomerId("");
      setCoordsToShowRoute(null);
      return;
    }
    setSelectedCustomerId(rowData.customer_id as string);
  };

  return (
    <StyledMain>
      {redirect && <Redirect to={redirect} />}
      <Sidebar
        apiReady={apiReady}
        inputQuery={inputQuery}
        onInputChange={handleInputChange}
        onSearch={handleSearchAddress}
        activeCategory={activeCategory}
        setActiveCategory={handleSetActiveCategory}
        setShowCreationForm={setShowCreationForm}
        showCreationForm={showCreationForm}
        cancelCreation={cancelCreation}
        maps={maps}
        onSave={handleSaveLocation}
        locations={allLocations}
        setLocations={setAllLocations}
        onDelete={handleDeleteLocation}
        onSaveChanges={handleChangeItem}
        customerRadius={customerRadius}
        setCustomerRadius={setCustomerRadius}
        settlingRadius={settlingRadius}
        setSettlingRadius={setSettlingRadius}
        selectedCustomerId={selectedCustomerId}
        setSelectedCustomerId={setSelectedCustomerId}
        infoGroups={infoGroups}
        handleSetCoordsToShowRoute={handleSetCoordsToShowRoute}
        coordsToShowRoute={coordsToShowRoute}
        editLocation={editLocation}
        setEditLocationForMap={(
          location: {
            location: {
              lat: number;
              lng: number;
            };
            formatted_address: string;
          } | null
        ) => setEditLocation(location)}
        handleCustomerTableRowClick={handleCustomerTableRowClick}
        tableView={tableView}
      />
      <MapArea
        center={{
          lat: 51.05,
          lng: 13.73
        }}
        zoom={mapZoom}
        handleApiLoaded={handleApiLoaded}
        locations={allLocations}
        setMapZoom={setMapZoom}
        map={map}
        maps={maps}
        customerRadius={customerRadius}
        setSettlingRadius={setSettlingRadius}
        setCustomerRadius={setCustomerRadius}
        settlingRadius={settlingRadius}
        selectedCustomerId={selectedCustomerId}
        infoGroups={infoGroups}
        searchedLocation={searchedLocation}
        setSearchedLocation={setSearchedLocation}
        editLocation={editLocation}
        setEditLocationForMap={(
          location: {
            location: {
              lat: number;
              lng: number;
            };
            formatted_address: string;
          } | null
        ) => setEditLocation(location)}
        groupsToShow={groupsToShow}
        setGroupsToShow={setGroupsToShow}
        activeCategory={activeCategory}
        tableView={tableView}
        setTableView={setTableView}
      />
    </StyledMain>
  );
}

export default Main;
