/* eslint-disable react/display-name */
import React, { useEffect, useMemo, useState } from "react";
import MaterialTable, { Column } from "material-table";
import { CustomerSubType, Location, RowData } from "../../types";
import { Category } from "../../Views/Main";
import { CSSProperties } from "@material-ui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faPen } from "@fortawesome/free-solid-svg-icons";

const checkIfRouteActive = (
  coordsToShowRoute: {
    origin: { lat: number; lng: number };
    destination: { lat: number; lng: number };
  },
  currCoords: {
    origin: { lat: number; lng: number };
    destination: { lat: number; lng: number };
  }
) => {
  return (
    coordsToShowRoute.origin.lat === currCoords.origin.lat &&
    coordsToShowRoute.origin.lng === currCoords.origin.lng &&
    coordsToShowRoute.destination.lat === currCoords.destination.lat &&
    coordsToShowRoute.destination.lng === currCoords.destination.lng
  );
};

const CustomersTable = ({
  locations,
  handleSetCoordsToShowRoute,
  coordsToShowRoute,
  handleRowClick,
  selectedCustomerId,
  itemIdToEdit,
  setItemIdToEdit,
  showCreationForm,
  showDistList,
  setShowDistList,
  tableView
}: {
  locations: Location[];
  onChange: (
    itemId: string,
    newDesc: string,
    newType: {
      main: string;
      sub?:
        | {
            title: string;
            color: string;
            __typename: Category.COMPETITION;
          }
        | CustomerSubType;
    },
    newLocation?: Pick<Location, "location"> | undefined,
    newFormattedAddress?: string | undefined
  ) => Promise<void>;
  handleSetCoordsToShowRoute: (
    newCoords: {
      origin: {
        lat: number;
        lng: number;
      };
      destination: {
        lat: number;
        lng: number;
      };
    } | null
  ) => void;
  coordsToShowRoute: {
    origin: {
      lat: number;
      lng: number;
    };
    destination: {
      lat: number;
      lng: number;
    };
  } | null;
  handleRowClick: (rowData: RowData) => void;
  selectedCustomerId: string;
  itemIdToEdit: string | null;
  setItemIdToEdit: React.Dispatch<React.SetStateAction<string | null>>;
  showCreationForm: boolean;
  showDistList: boolean;
  setShowDistList: React.Dispatch<React.SetStateAction<boolean>>;
  tableView: "reduced" | "full";
}) => {
  const columns: Column<any>[] = [
    {
      title: "Beschreibung",
      field: "description",
      customFilterAndSearch: (term, rowData) => {
        if (Object.keys(rowData).length === 2) {
          return true;
        } else
          return rowData.description.toLowerCase().includes(term.toLowerCase());
      }
    },
    {
      title: "Adresse",
      field: "formatted_address"
    },
    {
      title: "Objekt-Nr.",
      field: "object_number"
    },
    {
      title: "Submiss",
      field: "submiss_date"
    },
    {
      title: "Bauzeit",
      field: "construction_time"
    },
    {
      title: "Tonnage",
      field: "tonnage"
    },
    {
      title: "Kategorie",
      field: "category"
    },
    {
      title: "",
      sorting: false,
      render: data =>
        Object.keys(data).length !== 2 && (
          <EditIcon
            rowData={data as RowData}
            showDistList={showDistList}
            setShowDistList={setShowDistList}
            itemIdToEdit={itemIdToEdit}
            setItemIdToEdit={setItemIdToEdit}
          />
        )
    },
    {
      title: "",
      sorting: false,
      render: data =>
        Object.keys(data).length !== 2 && (
          <DistListIcon
            onClick={setShowDistList}
            selectedCustomerId={selectedCustomerId}
            itemIdToEdit={itemIdToEdit}
            setItemIdToEdit={setItemIdToEdit}
            data={data}
          />
        )
    }
  ];

  if (tableView === "full") {
    columns.splice(
      7,
      0,
      ...([
        {
          title: "Ausschreibung",
          field: "winner_first_level"
        },
        {
          title: "Mischwerk",
          field: "winner_second_level"
        },
        {
          title: "Entf. 1",
          field: "dist_1",
          render: data => {
            return (
              <span
                style={{
                  cursor: "pointer",
                  fontWeight:
                    coordsToShowRoute && data.dist_1
                      ? checkIfRouteActive(coordsToShowRoute, {
                          origin: data.location,
                          destination: data.dist_1.location
                        })
                        ? "bold"
                        : "normal"
                      : "normal",
                  color: data.dist_1
                    ? data.dist_1.type === Category.COMPETITION
                      ? "#dc3545"
                      : "#0275d8"
                    : ""
                }}
                title={data.dist_1?.description}
                hidden={!data.dist_1}
                onClick={e => {
                  e.stopPropagation();
                  if (
                    coordsToShowRoute &&
                    checkIfRouteActive(coordsToShowRoute, {
                      origin: data.location,
                      destination: data.dist_1.location
                    })
                  ) {
                    handleSetCoordsToShowRoute(null);
                  } else
                    handleSetCoordsToShowRoute({
                      origin: data.location,
                      destination: data.dist_1.location
                    });
                }}
              >
                {data.dist_1?.distance}
              </span>
            );
          }
        },
        {
          title: "Entf. 2",
          field: "dist_2",
          render: data => {
            return (
              <span
                style={{
                  cursor: "pointer",
                  fontWeight:
                    coordsToShowRoute && data.dist_2
                      ? checkIfRouteActive(coordsToShowRoute, {
                          origin: data.location,
                          destination: data.dist_2.location
                        })
                        ? "bold"
                        : "normal"
                      : "normal",
                  color: data.dist_2
                    ? data.dist_2.type === Category.COMPETITION
                      ? "#dc3545"
                      : "#0275d8"
                    : ""
                }}
                title={data.dist_2?.description}
                hidden={!data.dist_2}
                onClick={e => {
                  e.stopPropagation();
                  if (
                    coordsToShowRoute &&
                    checkIfRouteActive(coordsToShowRoute, {
                      origin: data.location,
                      destination: data.dist_2.location
                    })
                  ) {
                    handleSetCoordsToShowRoute(null);
                  } else
                    handleSetCoordsToShowRoute({
                      origin: data.location,
                      destination: data.dist_2.location
                    });
                }}
              >
                {data.dist_2?.distance}
              </span>
            );
          }
        },
        {
          title: "Entf. 3",
          field: "dist_3",
          render: data => {
            return (
              <span
                style={{
                  cursor: "pointer",
                  fontWeight:
                    coordsToShowRoute && data.dist_3
                      ? checkIfRouteActive(coordsToShowRoute, {
                          origin: data.location,
                          destination: data.dist_3.location
                        })
                        ? "bold"
                        : "normal"
                      : "normal",
                  color: data.dist_3
                    ? data.dist_3.type === Category.COMPETITION
                      ? "#dc3545"
                      : "#0275d8"
                    : ""
                }}
                title={data.dist_3?.description}
                hidden={!data.dist_3}
                onClick={e => {
                  e.stopPropagation();
                  if (
                    coordsToShowRoute &&
                    checkIfRouteActive(coordsToShowRoute, {
                      origin: data.location,
                      destination: data.dist_3.location
                    })
                  ) {
                    handleSetCoordsToShowRoute(null);
                  } else
                    handleSetCoordsToShowRoute({
                      origin: data.location,
                      destination: data.dist_3.location
                    });
                }}
              >
                {data.dist_3?.distance}
              </span>
            );
          }
        },
        {
          title: "Entf. 4",
          field: "dist_4",
          render: data => {
            return (
              <span
                style={{
                  cursor: "pointer",
                  fontWeight:
                    coordsToShowRoute && data.dist_4
                      ? checkIfRouteActive(coordsToShowRoute, {
                          origin: data.location,
                          destination: data.dist_4.location
                        })
                        ? "bold"
                        : "normal"
                      : "normal",
                  color: data.dist_4
                    ? data.dist_4.type === Category.COMPETITION
                      ? "#dc3545"
                      : "#0275d8"
                    : ""
                }}
                title={data.dist_4?.description}
                hidden={!data.dist_4}
                onClick={e => {
                  e.stopPropagation();
                  if (
                    coordsToShowRoute &&
                    checkIfRouteActive(coordsToShowRoute, {
                      origin: data.location,
                      destination: data.dist_4.location
                    })
                  ) {
                    handleSetCoordsToShowRoute(null);
                  } else
                    handleSetCoordsToShowRoute({
                      origin: data.location,
                      destination: data.dist_4.location
                    });
                }}
              >
                {data.dist_4?.distance}
              </span>
            );
          }
        },
        {
          title: "Entf. 5",
          field: "dist_5",
          render: data => {
            return (
              <span
                style={{
                  cursor: "pointer",
                  fontWeight:
                    coordsToShowRoute && data.dist_5
                      ? checkIfRouteActive(coordsToShowRoute, {
                          origin: data.location,
                          destination: data.dist_5.location
                        })
                        ? "bold"
                        : "normal"
                      : "normal",
                  color: data.dist_5
                    ? data.dist_5.type === Category.COMPETITION
                      ? "#dc3545"
                      : "#0275d8"
                    : ""
                }}
                title={data.dist_5?.description}
                hidden={!data.dist_5}
                onClick={e => {
                  e.stopPropagation();
                  if (
                    coordsToShowRoute &&
                    checkIfRouteActive(coordsToShowRoute, {
                      origin: data.location,
                      destination: data.dist_5.location
                    })
                  ) {
                    handleSetCoordsToShowRoute(null);
                  } else
                    handleSetCoordsToShowRoute({
                      origin: data.location,
                      destination: data.dist_5.location
                    });
                }}
              >
                {data.dist_5?.distance}
              </span>
            );
          }
        }
      ] as Column<any>[])
    );
  }

  const data: RowData[] = useMemo(
    () =>
      locations
        .filter(loc => loc.type.main === Category.CUSTOMERS)
        .map(loc => {
          if (!loc.type.sub && typeof loc.type.sub !== "object") {
            return {
              description: loc.description,
              formatted_address: loc.formatted_address
            };
          }
          const subType = loc.type.sub as CustomerSubType;
          const obj_number = subType.object_number;
          let formatted_obj_number: string[] | string = "";
          if (obj_number) {
            formatted_obj_number = obj_number.toString().split("");
            formatted_obj_number.splice(2, 0, " ");
            formatted_obj_number = formatted_obj_number.join("");
          }
          return {
            description: loc.description,
            customer_id: loc.id,
            formatted_address: loc.formatted_address,
            location: loc.location,
            tonnage: subType.materials.reduce(
              (acc: number, curr) =>
                !isNaN(+curr.tonnage) ? acc + curr.tonnage : acc,
              0
            ),
            category: subType.materials
              .reduce((acc: string[], curr) => [...acc, curr.category], [])
              .join(", "),
            construction_time:
              subType.construction_time.from && subType.construction_time.to
                ? subType.construction_time.from
                    .split("-")
                    .reverse()
                    .join(".") +
                  " – " +
                  subType.construction_time.to.split("-").reverse().join(".")
                : "",
            winner_first_level: subType.winner_first_level,
            winner_second_level: subType.winner_second_level?.description || "",
            object_number: formatted_obj_number,
            submiss_date: subType.submiss_date?.split("-").reverse().join("."),
            dist_1: subType.nearest_neighbours[0] || "",
            dist_2: subType.nearest_neighbours[1] || "",
            dist_3: subType.nearest_neighbours[2] || "",
            dist_4: subType.nearest_neighbours[3] || "",
            dist_5: subType.nearest_neighbours[4] || ""
          };
        }),
    [locations]
  );

  const dataByMonth = useMemo(() => {
    let groups: any[] = [
      { title: "Sonstige", data: [{ description: "Sonstige" }] }
    ];
    for (const d of data) {
      if (!d.submiss_date) {
        groups = groups.map(g =>
          g.title === "Sonstige" ? { ...g, data: [...g.data, d] } : g
        );
      } else {
        const foundGroupIdx = groups
          .map(g => g.title)
          .indexOf(
            d.submiss_date
              .split(".")
              .filter((_, i) => i !== 0)
              .join(".")
          );
        if (foundGroupIdx !== -1) {
          groups = groups.map((g, idx) =>
            idx === foundGroupIdx ? { ...g, data: [...g.data, d] } : g
          );
        } else
          groups = [
            {
              title: d.submiss_date
                .split(".")
                .filter((_, i) => i !== 0)
                .join("."),
              data: [
                {
                  description: d.submiss_date
                    .split(".")
                    .filter((_, i) => i !== 0)
                    .join(".")
                },
                d
              ]
            },
            ...groups
          ];
      }
    }
    if (groups[groups.length - 1].data.length === 1) {
      groups = groups.filter((g, index, arr) => index !== arr.length - 1);
    }
    return groups;
  }, [data]);

  return (
    // <MaterialTable
    //   columns={columns}
    //   data={data}
    //   options={{
    //     paging: false,
    //     headerStyle: { position: "sticky", top: 0 },
    //     padding: "dense",
    //     rowStyle: {
    //       fontSize: "0.8rem"
    //     }
    //   }}
    // />

    <MaterialTable
      columns={columns.map(c => ({
        ...c,
        cellStyle: { padding: "10px calc(0.5%)", textAlign: "center" }
      }))}
      data={dataByMonth.reduce((acc: any[], curr: any) => {
        return [...acc, ...curr.data];
      }, [])}
      title="Baustellen"
      onRowClick={(e, data) => handleRowClick(data as RowData)}
      options={{
        paging: false,
        headerStyle: {
          position: "sticky",
          top: 0,
          whiteSpace: "nowrap",
          textAlign: "center",
          padding: "0 calc(0.5%)"
        },
        sorting: false,
        minBodyHeight: "50vh",
        maxBodyHeight: `calc(100vh - 150px ${
          showCreationForm ? "- 193px" : ""
        })`,
        rowStyle: (row: RowData) => {
          const fontStyle: CSSProperties =
            Object.keys(row).length === 2
              ? {
                  fontSize: "1.1rem",
                  fontWeight: "bold",
                  backgroundColor: "rgba(241, 241, 241, 0.708)"
                }
              : { fontSize: "0.8rem", fontWeight: "normal" };
          const selectedStyle: CSSProperties = {
            backgroundColor:
              selectedCustomerId === row.customer_id
                ? "rgba(224, 224, 224, 0.708)"
                : "inherit"
          };
          return {
            ...selectedStyle,
            ...fontStyle,
            textAlign: "center"
          };
        }
      }}
    />
  );
};

export default CustomersTable;

const EditIcon = ({
  rowData,
  itemIdToEdit,
  setItemIdToEdit,
  showDistList,
  setShowDistList
}: {
  rowData: RowData;
  itemIdToEdit: string | null;
  setItemIdToEdit: React.Dispatch<React.SetStateAction<string | null>>;
  showDistList: boolean;
  setShowDistList: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const isSelected = itemIdToEdit === rowData.customer_id;
  return (
    <div
      className="d-flex align-items-center justify-content-center"
      onClick={e => e.stopPropagation()}
    >
      <span
        style={{
          fontSize: "1.3rem",
          color: isSelected ? "lightblue" : "black"
        }}
        onClick={() => {
          if (isSelected) {
            setItemIdToEdit(null);
          } else {
            if (showDistList) setShowDistList(false);
            setItemIdToEdit(rowData.customer_id as string);
          }
        }}
      >
        <FontAwesomeIcon icon={faPen} />
      </span>
    </div>
  );
};

const DistListIcon = ({
  onClick,
  selectedCustomerId,
  data,
  itemIdToEdit,
  setItemIdToEdit
}: {
  onClick: React.Dispatch<React.SetStateAction<boolean>>;
  selectedCustomerId: string;
  data: RowData;
  itemIdToEdit: string | null;
  setItemIdToEdit: React.Dispatch<React.SetStateAction<string | null>>;
}) => {
  return selectedCustomerId === data.customer_id ? (
    <div
      style={{ fontSize: "1.3rem" }}
      className="d-flex align-items-center justify-content-center"
      onClick={e => {
        e.stopPropagation();
        if (itemIdToEdit) setItemIdToEdit(null);
        onClick(prev => !prev);
      }}
    >
      <span>
        <FontAwesomeIcon icon={faList} />
      </span>
    </div>
  ) : null;
};
