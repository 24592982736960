import React, { useState, useEffect, createContext } from "react";
import { auth, db } from "../firebaseConfig";
import { logOut } from "../services/auth";
import { useModal } from "./ModalContext";
export const UserContext = createContext<{
  displayName: string | null;
  email: any;
  firebaseId: string;
} | null>(null);

const UserProvider: React.FC = props => {
  const [user, setUser] = useState<{
    displayName: string | null;
    email: any;
    firebaseId: string;
  } | null>(null);
  const { showModal } = useModal();

  useEffect(() => {
    auth.onAuthStateChanged(async fbUser => {
      console.log("user changed", fbUser);
      if (fbUser === null) {
        setUser(null);
        return;
      }

      const ref = db.collection("profile");
      const dbUser = await ref
        .where("email", "==", fbUser.email)
        .limit(1)
        .get();
      if (dbUser.empty) {
        console.log("no permission");
        showModal({
          message:
            "Sie haben sich erfolgreich angemeldet. Bitten Sie einen Administrator ihr Profil freizuschalten",
          type: "succes"
        });
        await ref.add({
          email: fbUser.email,
          access: false,
          first_name: fbUser.displayName?.split(" ")[0] || "",
          last_name: fbUser.displayName?.split(" ")[1] || ""
        });
        return;
      }

      if (!dbUser.empty && !dbUser.docs[0].data().access) {
        console.log("no permission");
        showModal({
          message:
            "Du darfst diese Seite nicht besuchen, wende dich bitte an Administrator",
          type: "alert"
        });
        return;
      } else {
        setUser({
          displayName: fbUser.displayName,
          email: fbUser.email,
          firebaseId: dbUser.docs[0].id
        });
      }

      if (auth.currentUser) {
        console.warn(
          "Your token for http Requests",
          await auth.currentUser.getIdToken()
        );
      }
      showModal({ message: "Du bist jetzt eingeloggt", type: "succes" });
    });
  }, []);

  return (
    <UserContext.Provider value={user}>{props.children}</UserContext.Provider>
  );
};

export default UserProvider;
