import firebase from "firebase";
import "firebase/auth";

const projectName = process.env.REACT_APP_PROJECT_NAME;

const configs = {
  Kemna: {
    apiKey: "AIzaSyCpzjugcgUXXujZIgoRZX6w1gLrQmkPXs8",
    authDomain: "mapdistance-965ef.firebaseapp.com",
    databaseURL: "https://mapdistance-965ef.firebaseio.com",
    projectId: "mapdistance-965ef",
    storageBucket: "mapdistance-965ef.appspot.com",
    messagingSenderId: "1277917406",
    appId: "1:1277917406:web:d54dbd43caf4dd28d9ea4f"
  },
  Kutter: {
    apiKey: "AIzaSyB5pD3Q9-riDcS71q-5SFERrWvflMYeFhE",
    authDomain: "mapdistance2-fcafc.firebaseapp.com",
    databaseURL: "https://mapdistance2-fcafc.firebaseio.com",
    projectId: "mapdistance2-fcafc",
    storageBucket: "mapdistance2-fcafc.appspot.com",
    messagingSenderId: "1734651673",
    appId: "1:1734651673:web:6975a454d79af7a2771f88",
    measurementId: "G-XKTSYXG85R"
  }
};

const firebaseConfig =
  projectName && (projectName === "Kemna" || projectName === "Kutter")
    ? configs[projectName]
    : null;

firebase.initializeApp(firebaseConfig || {});

const db = firebase.firestore();
const auth = firebase.auth();
export const googleProvider = new firebase.auth.GoogleAuthProvider();

if (location.hostname === "localhost") {
  db.useEmulator("localhost", 8080);
  auth.useEmulator("http://localhost:9099");
}

export default firebase;

export { db, auth };
