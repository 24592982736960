import React from "react";
import { Location } from "../../types";
import DataRow, { DataRowProps } from "./DataRow";
import EditLocationForm, { EditLocationFormProps } from "./EditLocationForm";

type GroupItemProps = {
  groupItem: {
    subtype:
      | string
      | {
          title: string;
          color: string;
        };
    items: Location[];
  };
  itemIdToEdit: string | null;
  selectedCategories: string[];
  handleSwitchAllOfSubType: (subtype: string) => void;
} & Omit<EditLocationFormProps, "item"> &
  Omit<DataRowProps, "item">;

const GroupItem = ({
  groupItem,
  selectedCategories,
  handleSwitchAllOfSubType,
  itemIdToEdit,
  ...rest
}: GroupItemProps) => {
  return (
    <div>
      <div className="position-relative w-100 mt-2">
        <span>
          {typeof groupItem.subtype === "string"
            ? groupItem.subtype
            : groupItem.subtype.title}
        </span>
        <input
          type="checkbox"
          style={{ cursor: "pointer", marginLeft: 10 }}
          checked={selectedCategories.includes(
            typeof groupItem.subtype === "string"
              ? groupItem.subtype
              : groupItem.subtype.title
          )}
          value={
            typeof groupItem.subtype === "string"
              ? groupItem.subtype
              : groupItem.subtype.title
          }
          onChange={e => handleSwitchAllOfSubType(e.target.value)}
        />
        {typeof groupItem.subtype === "object" && (
          <span
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              bottom: 0,
              width: 5,
              backgroundColor: groupItem.subtype.color
            }}
          />
        )}
      </div>
      <div style={{ paddingLeft: 10 }}>
        {groupItem.items.map((item: Location) =>
          itemIdToEdit === item.id &&
          rest.changedItemDesc &&
          rest.changedItemSubType &&
          rest.changedItemType ? (
            <EditLocationForm key={item.id} {...rest} item={item} />
          ) : (
            <DataRow key={item.id} {...rest} item={item} hideColorBar={true} />
          )
        )}
      </div>
    </div>
  );
};

export default GroupItem;
