import {
  faChevronDown,
  faChevronUp,
  faCross,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import styled from "styled-components";
import { DropdownItem } from ".";
import { CustomerSubType, Location, Owner } from "../../types";
import { useMediaQuery } from "beautiful-react-hooks";
import { CSSProperties } from "@material-ui/styles";
import { v4 } from "uuid";

type CustomerEditFormProps = {
  itemIdToEdit: string;
  locations?: Location[];
  setChangedItemLocation: React.Dispatch<
    React.SetStateAction<Pick<Location, "location"> | null>
  >;
  changedItemFormattedAddress: string | null;
  setChangedItemFormattedAddress: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  changedItemDesc: string | null;
  setChangedItemDesc: React.Dispatch<React.SetStateAction<string | null>>;
  changedItemSubType: CustomerSubType;
  setChangedItemSubType: React.Dispatch<
    React.SetStateAction<CustomerSubType | null>
  >;
  filteredEditWithoutCustomers: Owner[];
  apiReady: boolean;
  maps: any;
  deleteCustomer?: (id: string) => Promise<void>;
  cancelEdit: () => void;
  saveChanges: () => Promise<void>;
  style?: CSSProperties;
};

const Wrapper = styled.div`
  label {
    font-size: 0.8rem;
    margin-bottom: 5px;
  }
  div.form-group {
    margin-bottom: 10px;
  }
`;

const CustomerEditForm = ({
  itemIdToEdit,
  locations,
  changedItemFormattedAddress,
  changedItemDesc,
  changedItemSubType: _changedItemSubtype,
  setChangedItemDesc,
  setChangedItemFormattedAddress,
  setChangedItemLocation,
  setChangedItemSubType,
  filteredEditWithoutCustomers,
  apiReady,
  maps,
  deleteCustomer,
  cancelEdit,
  saveChanges,
  style
}: CustomerEditFormProps) => {
  const [materialIdToShowDropdown, setMaterialIdToShowDropdown] = useState("");
  const [showWinnerDropdown, setShowWinnerDropdown] = useState(false);
  const [
    editAutoCompleteRef,
    setEditAutoCompleteRef
  ] = useState<HTMLInputElement | null>(null);
  const [formStyle, setFormStyle] = useState<CSSProperties>({});
  const isSmall = useMediaQuery("(max-height: 1000px)");
  const [winnerSelectFilter, setWinnerSelectFilter] = useState("");

  useEffect(() => {
    if (isSmall) {
      setFormStyle({ height: 720, overflowY: "scroll" });
    } else setFormStyle({});
  }, [isSmall]);

  useEffect(() => {
    if (!_changedItemSubtype) return;
    setWinnerSelectFilter(
      _changedItemSubtype.winner_second_level?.description || ""
    );
    return () => {
      setWinnerSelectFilter("");
    };
  }, [_changedItemSubtype, itemIdToEdit]);

  // useEffect(() => {

  // }, [winnerSelectFilter]);

  useEffect(() => {
    if (!apiReady || !editAutoCompleteRef) return;
    const ref = new maps.places.Autocomplete(editAutoCompleteRef, {
      types: ["geocode"],
      componentRestrictions: { country: "de" }
    });
    ref.setFields(["formatted_address", "geometry"]);
    ref.addListener("place_changed", () => {
      const address = ref.getPlace();
      const formattedAddress = address.formatted_address;
      const location = address.geometry.location;
      setChangedItemFormattedAddress(formattedAddress);
      setChangedItemLocation({
        location: { lat: location.lat(), lng: location.lng() }
      });
    });
  }, [apiReady, editAutoCompleteRef, itemIdToEdit]);

  const setAutoCompleteRef = useCallback((node: HTMLInputElement) => {
    setEditAutoCompleteRef(node);
  }, []);

  const handleDeleteCustomer = async () => {
    if (!deleteCustomer) return;
    if (!confirm("Bist du sicher, dass du diese Baustelle löschen möchtest"))
      return;
    await deleteCustomer(itemIdToEdit);
    cancelEdit();
  };

  const subType = _changedItemSubtype as CustomerSubType;

  const filteredWithoutCustomersToShow = useMemo(() => {
    return filteredEditWithoutCustomers.filter(l =>
      l.description.includes(winnerSelectFilter)
    );
  }, [winnerSelectFilter, filteredEditWithoutCustomers]);

  const filteredWithoutCustomersEditToShow = useMemo(() => {
    if (!locations || !subType) return [];

    const nearest_locations: Owner[] = subType.nearest_neighbours
      .map(
        n =>
          filteredEditWithoutCustomers.find(
            l => l.description === n.description && l.type === n.type
          ) as Owner
      )
      .filter(l => l !== undefined);
    return [
      ...nearest_locations,
      ...filteredEditWithoutCustomers.filter(
        k => !nearest_locations.find(nl => k.id === nl.id)
      )
    ].filter(l => l.description.includes(winnerSelectFilter));
  }, [subType, locations, winnerSelectFilter, filteredEditWithoutCustomers]);

  if (changedItemFormattedAddress === null || changedItemDesc === null) {
    return null;
  }

  return (
    <Wrapper
      className="position-absolute bg-light shadow px-3 py-4 d-flex flex-column"
      style={{
        top: "7vh",
        right: -410,
        zIndex: 1000,
        width: 400,
        borderRadius: 12,
        ...formStyle,
        ...style
      }}
    >
      <div className="form-group">
        <label htmlFor="address">Adresse</label>
        <div className="position-relative" style={{ overflow: "hidden" }}>
          <input
            ref={setAutoCompleteRef}
            className="form-control w-75"
            id="address"
            disabled={!apiReady}
            placeholder="Adresse eingeben"
            value={changedItemFormattedAddress}
            onChange={e => setChangedItemFormattedAddress(e.target.value)}
            aria-label="Search"
          />
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="address">Beschreibung</label>
        <input
          className="form-control w-75"
          id="description"
          value={changedItemDesc}
          onChange={e => setChangedItemDesc(e.target.value)}
          placeholder="Beschreibung eingeben"
        />
      </div>
      <div className="w-100 d-flex">
        <div className="form-group w-50 mr-3">
          <label htmlFor="obj_number">Objektnummer Kifa</label>
          <input
            className="form-control"
            value={subType.object_number || 0}
            onChange={e =>
              !isNaN(+e.target.value) &&
              setChangedItemSubType({
                ...subType,
                object_number: +e.target.value
              })
            }
            id="obj_number"
            placeholder="Objektnummer"
          />
        </div>
        <div className="form-group w-50">
          <label htmlFor="address">Datum Submission</label>
          <input
            className="form-control"
            type="date"
            value={subType.submiss_date}
            onChange={e =>
              setChangedItemSubType({
                ...subType,
                submiss_date: e.target.value
              })
            }
            id="description"
            placeholder="Beschreibung eingeben"
          />
        </div>
      </div>
      <h6 className="mb-0">
        <strong>Bauzeit</strong>
      </h6>
      <div className="w-100 d-flex justify-content-between">
        <div className="form-group" style={{ width: "48%" }}>
          <label htmlFor="obj_number">Von</label>
          <input
            className="form-control"
            type="date"
            value={
              subType.construction_time.from ||
              new Date().toLocaleDateString("en-ZA").split("/").join("-")
            }
            onChange={e =>
              setChangedItemSubType({
                ...subType,
                construction_time: {
                  ...subType.construction_time,
                  from: e.target.value
                }
              })
            }
            id="obj_number"
          />
        </div>
        <div className="form-group" style={{ width: "48%" }}>
          <label htmlFor="address">Bis</label>
          <input
            className="form-control"
            type="date"
            value={
              subType.construction_time.to ||
              new Date().toLocaleDateString("en-ZA").split("/").join("-")
            }
            onChange={e =>
              setChangedItemSubType({
                ...subType,
                construction_time: {
                  ...subType.construction_time,
                  to: e.target.value
                }
              })
            }
            id="obj_number"
          />
        </div>
      </div>
      <hr className="w-100" />
      <h6>
        <strong>Material</strong>
      </h6>
      {subType.materials.map((m, key) => (
        <Fragment key={key}>
          <div className="w-100 d-flex align-items-center">
            <div className="form-group w-50 mr-3">
              <label htmlFor="obj_number">Tonnage</label>
              <input
                className="form-control"
                type="number"
                value={m.tonnage}
                onChange={e =>
                  setChangedItemSubType({
                    ...subType,
                    materials: subType.materials.map(mat =>
                      mat.id === m.id
                        ? { ...mat, tonnage: +e.target.value }
                        : mat
                    )
                  })
                }
                id="obj_number"
                placeholder="Beschreibung eingeben"
              />
            </div>
            <div className="form-group w-50">
              <label htmlFor="address">Material</label>
              <div className="w-100 position-relative">
                <input
                  className="w-100 form-control"
                  style={{ height: 35 }}
                  placeholder="Kategorie"
                  type="text"
                  value={m.category}
                  readOnly
                />
                <FontAwesomeIcon
                  icon={
                    materialIdToShowDropdown === m.id
                      ? faChevronUp
                      : faChevronDown
                  }
                  style={{
                    position: "absolute",
                    right: 10,
                    top: 10,
                    cursor: "pointer"
                  }}
                  onClick={() =>
                    setMaterialIdToShowDropdown(
                      m.id === materialIdToShowDropdown ? "" : m.id
                    )
                  }
                />
                {materialIdToShowDropdown === m.id && (
                  <div
                    style={{
                      position: "absolute",
                      top: "100%",
                      zIndex: 100,
                      maxHeight: 180,
                      overflowY: "scroll",
                      left: 0,
                      right: 0
                    }}
                  >
                    {[
                      "",
                      "Keine",
                      "Tragschicht",
                      "Binder",
                      "Decke",
                      "SMA",
                      "Gußaphalt",
                      "Tragdeckschicht",
                      "Sonstiges"
                    ].map((v, index) => (
                      <DropdownItem
                        key={index}
                        className="p-1 bg-white border-bottom border-right border-left"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setChangedItemSubType({
                            ...subType,
                            materials: subType.materials.map(mat =>
                              mat.id === m.id ? { ...mat, category: v } : mat
                            )
                          });
                          setMaterialIdToShowDropdown("");
                        }}
                      >
                        {!v ? "Kategorie" : v}
                      </DropdownItem>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div
              className="ml-2"
              style={{
                transform: "rotate(45deg)",
                fontSize: "1.4rem",
                cursor: "pointer"
              }}
              onClick={() =>
                setChangedItemSubType({
                  ...subType,
                  materials: subType.materials.filter(mat => mat.id !== m.id)
                })
              }
            >
              +
            </div>
          </div>
        </Fragment>
      ))}
      <div>
        <button
          className="btn btn-outline-primary float-right"
          style={{ padding: "0 6px" }}
          onClick={() =>
            setChangedItemSubType({
              ...subType,
              materials: [
                ...subType.materials,
                { tonnage: 0, category: "", id: v4() }
              ]
            })
          }
        >
          +
        </button>
      </div>
      <div className="form-group">
        <label>Notizen</label>
        <textarea
          className="form-control"
          placeholder="Anfrage von: ..."
          rows={4}
          value={subType.notes}
          onChange={e =>
            setChangedItemSubType({
              ...subType,
              notes: e.target.value
            })
          }
          style={{ resize: "none" }}
        />
      </div>
      <hr className="w-100" />
      <div className="form-group w-100">
        <h6>
          <strong>Submission</strong>
        </h6>
        <div className="w-100 d-flex">
          <div className="form-group w-50 mr-3">
            <label htmlFor="winner_1">Gewinner Ausschreibung</label>
            <input
              className="form-control"
              type="text"
              id="winner_1"
              value={subType.winner_first_level}
              onChange={e =>
                setChangedItemSubType({
                  ...subType,
                  winner_first_level: e.target.value
                })
              }
              placeholder="Gewinner 1"
            />
          </div>
          <div className="form-group w-50">
            <label htmlFor="address">Gewinner Mischwerk</label>
            <div className="w-100 position-relative mb-2">
              <input
                className="w-100 form-control"
                style={{ height: 35 }}
                placeholder="Gewinner 2"
                type="text"
                value={winnerSelectFilter}
                onChange={e => {
                  setWinnerSelectFilter(e.target.value);
                  if (!e.target.value.length) {
                    setShowWinnerDropdown(false);
                  } else if (!showWinnerDropdown) {
                    setShowWinnerDropdown(true);
                  }
                }}
              />
              <FontAwesomeIcon
                icon={showWinnerDropdown ? faChevronUp : faChevronDown}
                style={{
                  position: "absolute",
                  right: 10,
                  top: 10,
                  cursor: "pointer"
                }}
                onClick={() => setShowWinnerDropdown(!showWinnerDropdown)}
              />
              {showWinnerDropdown && (
                <div
                  style={{
                    position: "absolute",
                    top: "100%",
                    zIndex: 100,
                    maxHeight: 180,
                    overflowY: "scroll",
                    left: 0,
                    right: 0
                  }}
                >
                  {!!filteredWithoutCustomersEditToShow.length
                    ? filteredWithoutCustomersEditToShow.map((v, index) => (
                        <DropdownItem
                          key={index}
                          className="p-1 bg-white border-bottom border-right border-left"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setChangedItemSubType({
                              ...subType,
                              winner_second_level: v
                            });
                            setShowWinnerDropdown(false);
                          }}
                        >
                          {v.description}
                        </DropdownItem>
                      ))
                    : filteredWithoutCustomersToShow.map((v, index) => (
                        <DropdownItem
                          key={index}
                          className="p-1 bg-white border-bottom border-right border-left"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setChangedItemSubType({
                              ...subType,
                              winner_second_level: v
                            });
                            setShowWinnerDropdown(false);
                          }}
                        >
                          {v.description}
                        </DropdownItem>
                      ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="w-100 d-flex justify-content-between align-items-center mt-1">
        {deleteCustomer && (
          <button
            className="btn btn-outline-danger"
            onClick={handleDeleteCustomer}
          >
            Löschen
          </button>
        )}
        <button className="btn btn-outline-secondary" onClick={cancelEdit}>
          Abbrechen
        </button>
        <button className="btn btn-success" onClick={saveChanges}>
          Speichern
        </button>
      </div>
    </Wrapper>
  );
};

export default CustomerEditForm;
