import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { DropdownItem } from ".";
import { CustomerSubType } from "../../types";
import { Category } from "../../Views/Main";

type CreationFormProps = {
  apiReady: boolean;
  description: string;
  setDescription: (value: string) => void;
  type: string;
  setType: (value: string) => void;
  handleSetType: (e: ChangeEvent<HTMLInputElement>) => void;
  companyName: string;
  subType:
    | CustomerSubType
    | {
        title: string;
        color: string;
      }
    | null;
  setSubType: (
    value: React.SetStateAction<
      | CustomerSubType
      | {
          title: string;
          color: string;
        }
      | null
    >
  ) => void;
  allSubTypes: {
    main: string;
    sub:
      | {
          title: string;
          color: string;
        }
      | string;
  }[];
  allowColorSelect: boolean;
  cancelCreation: () => void;
  handleSave: () => void;
};

const CreationForm: React.FC<CreationFormProps> = ({
  apiReady,
  description,
  setDescription,
  type,
  setType,
  handleSetType,
  companyName,
  subType,
  setSubType,
  allSubTypes,
  allowColorSelect,
  cancelCreation,
  handleSave
}) => {
  const [showTypeDropDown, setShowTypeDropDown] = useState(false);

  useEffect(() => {
    if (
      subType &&
      "title" in subType &&
      subType.title &&
      filteredSubtypes.length
    ) {
      setShowTypeDropDown(true);
    } else setShowTypeDropDown(false);
  }, [subType]);

  const filteredSubtypes = useMemo(
    () =>
      allSubTypes.filter(t => {
        if (subType && typeof t.sub === "string" && "title" in subType) {
          return t.sub.includes(subType.title);
        } else if (
          subType &&
          typeof t.sub === "object" &&
          "title" in subType &&
          "title" in t.sub
        ) {
          return t.sub.title.includes(subType.title);
        }
      }),
    [allSubTypes, subType]
  );

  return (
    <div
      className="w-100 mt-2"
      style={{
        overflowY: "scroll",
        height: 160
      }}
    >
      <div style={{ height: 35 }}>
        <input
          className="form-control form-control-sm w-100 h-100 pr-5"
          type="text"
          disabled={!apiReady}
          placeholder="Bezeichnung eingeben"
          value={description}
          onChange={e => setDescription(e.target.value)}
          aria-label="Search"
        />
      </div>
      <div className="mt-2">
        <input
          type="radio"
          name="type"
          id="Niederlassung"
          value={Category.SETTLING}
          checked={type === Category.SETTLING}
          onChange={handleSetType}
          className="mr-2"
        />
        <label htmlFor="Niederlassung">{companyName}</label>
        <br />
        <input
          type="radio"
          name="type"
          id="Konkurrenz"
          value={Category.COMPETITION}
          checked={type === Category.COMPETITION}
          onChange={handleSetType}
          className="mr-2"
        />
        <label htmlFor="Konkurrenz">Konkurrenz</label>
        {!!(
          type === Category.COMPETITION &&
          subType &&
          "title" in subType &&
          "color" in subType
        ) && (
          <>
            <div className="w-75 position-relative">
              <input
                className="w-100 form-control"
                style={{ height: 35 }}
                placeholder="Kategorie"
                type="text"
                value={subType.title}
                onChange={e => setSubType({ title: e.target.value, color: "" })}
              />
              <FontAwesomeIcon
                icon={showTypeDropDown ? faChevronUp : faChevronDown}
                style={{
                  position: "absolute",
                  right: 10,
                  top: 10,
                  cursor: "pointer"
                }}
                onClick={() => setShowTypeDropDown(!showTypeDropDown)}
              />
              {showTypeDropDown && (
                <div
                  style={{
                    position: "absolute",
                    top: "100%",
                    zIndex: 100,
                    maxHeight: 300,
                    overflowY: "scroll",
                    left: 0,
                    right: 0
                  }}
                >
                  {filteredSubtypes.map((it, index) =>
                    it.main === Category.COMPETITION && it.sub ? (
                      <DropdownItem
                        key={index}
                        className="p-1 bg-white border-bottom border-right border-left"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (typeof it.sub === "string") {
                            const title = it.sub;
                            setSubType({
                              title: title,
                              color: "#dc3545"
                            });
                          } else {
                            const item = it.sub as {
                              title: string;
                              color: string;
                            };
                            setSubType({
                              title: item.title,
                              color: item.color
                            });
                          }
                          setImmediate(() => setShowTypeDropDown(false));
                        }}
                      >
                        {typeof it.sub === "string" ? it.sub : it.sub.title}
                      </DropdownItem>
                    ) : null
                  )}
                </div>
              )}
            </div>
            {allowColorSelect && (
              <div className="mt-1 w-75 position-relative">
                <input
                  type="text"
                  className="w-100 form-control"
                  value={subType.color}
                  placeholder="Farbe wählen"
                  style={{ height: 35 }}
                />
                <input
                  type="color"
                  className="position-absolute"
                  placeholder="Choose color"
                  value={subType.color}
                  style={{
                    height: 35,
                    width: 35,
                    right: 0,
                    top: 0,
                    cursor: "pointer"
                  }}
                  onChange={e =>
                    setSubType({
                      title: subType.title,
                      color: e.target.value
                    })
                  }
                />
              </div>
            )}
          </>
        )}
      </div>
      <div className="d-flex">
        <button
          className="btn btn-outline-danger w-50 mt-2 mr-1"
          onClick={() => {
            setDescription("");
            setSubType({ title: "", color: "" });
            setType(Category.SETTLING);
            cancelCreation();
          }}
        >
          Abbrechen
        </button>
        <button
          className="btn btn-outline-primary w-50 mt-2 ml-1"
          onClick={handleSave}
        >
          Speichern
        </button>
      </div>
    </div>
  );
};

export default CreationForm;
