/* eslint-disable @typescript-eslint/ban-ts-comment */
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import React, {
  useCallback,
  useEffect,
  useState,
  ChangeEvent,
  useMemo,
  useContext
} from "react";
import { Category } from "../../Views/Main";
import DistanceList from "./DistanceList";
import { logOut } from "../../services/auth";
import { useModal } from "../../context/ModalContext";
import { isEqual } from "lodash";
import {
  CustomerSubType,
  InfoGroup,
  Location,
  Owner,
  RowData
} from "../../types";
import CustomersTable from "./CustomersTable";
import { v4 } from "uuid";
import CustomerEditForm from "./CustomerEditForm";
import { db } from "../../firebaseConfig";
import { UserContext } from "../../context/user";
import { useMixPanel } from "../../util/Mixpanel";
import CreationForm from "./CreationForm";
import EditLocationForm from "./EditLocationForm";
import DataRow from "./DataRow";
import GroupItem from "./GroupItem";
import Header from "./Header";

type SidebarProps = {
  apiReady: boolean;
  inputQuery: string;
  onInputChange: (value: string) => void;
  onSearch: () => Promise<void>;
  activeCategory: string;
  setActiveCategory: (category: Category) => void;
  setShowCreationForm: React.Dispatch<React.SetStateAction<boolean>>;
  showCreationForm: boolean;
  maps: unknown;
  onSave: (
    description: string,
    type: {
      main: string;
      sub?:
        | {
            title: string;
            color: string;
            __typename: Category.COMPETITION;
          }
        | CustomerSubType;
    }
  ) => Promise<void>;
  locations: Location[];
  setLocations: React.Dispatch<React.SetStateAction<Location[]>>;
  onDelete: (id: string) => Promise<void>;
  onSaveChanges: (
    itemId: string,
    newDesc: string,
    newType: {
      main: string;
      sub?:
        | {
            title: string;
            color: string;
          }
        | CustomerSubType;
    },
    newLocation?: Pick<Location, "location">,
    newFormattedAddress?: string
  ) => Promise<void>;
  customerRadius: number;
  settlingRadius: number;
  setCustomerRadius: React.Dispatch<React.SetStateAction<number>>;
  setSettlingRadius: React.Dispatch<React.SetStateAction<number>>;
  selectedCustomerId: string;
  setSelectedCustomerId: React.Dispatch<React.SetStateAction<string>>;
  infoGroups: InfoGroup[];
  cancelCreation: () => void;
  handleSetCoordsToShowRoute: (
    newCoords: {
      origin: {
        lat: number;
        lng: number;
      };
      destination: {
        lat: number;
        lng: number;
      };
    } | null
  ) => void;
  coordsToShowRoute: {
    origin: {
      lat: number;
      lng: number;
    };
    destination: {
      lat: number;
      lng: number;
    };
  } | null;
  editLocation: {
    location: {
      lat: number;
      lng: number;
    };
    formatted_address: string;
  } | null;
  setEditLocationForMap: (
    location: {
      location: {
        lat: number;
        lng: number;
      };
      formatted_address: string;
    } | null
  ) => void;
  handleCustomerTableRowClick: (rowData: RowData) => void;
  tableView: "full" | "reduced";
};

const StyledSidebar = styled.div<{
  isTableView: boolean;
  tableView: "full" | "reduced";
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 350px;
  width: ${({ isTableView, tableView }) =>
    isTableView ? (tableView === "reduced" ? "50vw" : "75vw") : "25vw"};
  max-width: ${({ isTableView }) => (!isTableView ? "800px" : "auto")};
  padding: 15px;
  transition: width 0.2s cubic-bezier(0.57, 0.23, 0.76, 0.73);
`;

const StyledList = styled.div`
  overflow-y: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
  }
`;

const SearchButton = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const DropdownItem = styled.div`
  &:hover {
    background-color: #c0c0c0 !important;
  }
`;

const Sidebar = ({
  apiReady,
  inputQuery,
  onInputChange,
  onSearch,
  activeCategory,
  setActiveCategory,
  setShowCreationForm,
  showCreationForm,
  maps,
  onSave,
  locations,
  setLocations,
  onDelete,
  onSaveChanges,
  customerRadius,
  settlingRadius,
  selectedCustomerId,
  setSelectedCustomerId,
  infoGroups,
  cancelCreation,
  handleSetCoordsToShowRoute,
  coordsToShowRoute,
  editLocation,
  setEditLocationForMap,
  handleCustomerTableRowClick,
  tableView
}: SidebarProps) => {
  const [description, setDescription] = useState("");
  const [type, setType] = useState<string>(Category.SETTLING);
  const [subType, setSubType] = useState<
    CustomerSubType | { title: string; color: string } | null
  >(null);
  const [showEditTypeDropDown, setShowEditTypeDropDown] = useState(false);
  const [
    materialEditIdToShowDropdown,
    setMaterialEditIdToShowDropdown
  ] = useState("");
  const [showMenu, setShowMenu] = useState(false);
  const [showEditOwnerDropDown, setShowEditOwnerDropDown] = useState(false);
  const [showDistList, setShowDistList] = useState(false);
  const [itemIdToEdit, setItemIdToEdit] = useState<string | null>(null);
  const [changedItemLocation, setChangedItemLocation] = useState<Pick<
    Location,
    "location"
  > | null>(null);
  const [
    changedItemFormattedAddress,
    setChangedItemFormattedAddress
  ] = useState<string | null>(null);
  const [changedItemDesc, setChangedItemDesc] = useState<string | null>(null);
  const [changedItemType, setChangedItemType] = useState<string | null>(null);
  const [changedItemSubType, setChangedItemSubType] = useState<
    CustomerSubType | { title: string; color: string } | null
  >(null);
  const [itemIdToShowOptions, setItemIdToShowOptions] = useState("");
  const [allowColorSelect, setAllowColorSelect] = useState(false);
  const [
    autocompleteRef,
    setAutoCompleteRef
  ] = useState<HTMLInputElement | null>(null);
  const [
    editAutoCompleteRef,
    setEditAutoCompleteRef
  ] = useState<HTMLInputElement | null>(null);
  const [allSettlingsSelected, setAllSettlingsSelected] = useState(true);
  const [allCompetitionsSelected, setAllCompetitionsSelected] = useState(true);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [editModalOpening, setEditModalOpening] = useState(false);
  const [customerOwnerEditFilter, setCustomerOwnerEditFilter] = useState("");

  const companyName = process.env.REACT_APP_COMPANY_NAME || "Niederlassung";
  const user = useContext(UserContext);
  const { mp } = useMixPanel();

  const { showModal } = useModal();

  useEffect(() => {
    if (!apiReady || !editAutoCompleteRef || !itemIdToEdit) return;
    // @ts-ignore
    const autoCompleteEdit = new maps.places.Autocomplete(editAutoCompleteRef, {
      types: ["geocode"],
      componentRestrictions: { country: "de" }
    });
    autoCompleteEdit.setFields(["formatted_address", "geometry"]);
    autoCompleteEdit.addListener("place_changed", () => {
      const address = autoCompleteEdit.getPlace();
      const formattedAddress = address.formatted_address;
      const location = address.geometry.location;
      setChangedItemFormattedAddress(formattedAddress);
      setChangedItemLocation({
        location: { lat: location.lat(), lng: location.lng() }
      });
    });
  }, [apiReady, editAutoCompleteRef, itemIdToEdit]);

  useEffect(() => {
    if (!apiReady || !autocompleteRef) return;
    // @ts-ignore
    const autoComplete = new maps.places.Autocomplete(autocompleteRef, {
      types: ["geocode"],
      componentRestrictions: { country: "de" }
    });
    autoComplete.setFields(["formatted_address", "geometry"]);
    autoComplete.addListener("place_changed", () => {
      const address = autoComplete.getPlace();
      const query = address.formatted_address;
      onInputChange(query);
    });
  }, [apiReady, autocompleteRef]);

  useEffect(() => {
    if (itemIdToEdit && filteredList) {
      const itemToEdit = filteredList.find(i => i.id === itemIdToEdit);
      if (itemToEdit) {
        setChangedItemLocation({ location: itemToEdit.location });
        setChangedItemFormattedAddress(itemToEdit.formatted_address);
        setChangedItemDesc(itemToEdit.description);
        setChangedItemType(itemToEdit.type.main);
        setChangedItemSubType({ title: "", color: "" });
        if (itemToEdit.type.main === Category.COMPETITION) {
          if (!itemToEdit.type.sub) {
            setChangedItemSubType({ title: "", color: "" });
          } else if (typeof itemToEdit.type.sub === "string") {
            setChangedItemSubType({ title: itemToEdit.type.sub, color: "" });
          } else {
            setChangedItemSubType(itemToEdit.type.sub);
          }
        } else if (itemToEdit.type.main === Category.CUSTOMERS) {
          if (!itemToEdit.type.sub) {
            setChangedItemSubType({
              tonnage: 0,
              category: "",
              materials: [{ tonnage: 0, category: "", id: v4() }],
              winner_first_level: "",
              winner_second_level: null,
              construction_time: {
                from: "",
                to: ""
              },
              submiss_date: "",
              object_number: null,
              nearest_neighbours: [],
              __typename: Category.CUSTOMERS
            });
          } else {
            setChangedItemSubType(itemToEdit.type.sub as CustomerSubType);
            setCustomerOwnerEditFilter(
              (itemToEdit.type.sub as CustomerSubType).winner_second_level
                ?.description || ""
            );
          }
        }
      }
    }
  }, [itemIdToEdit]);

  useEffect(() => {
    if (
      subType &&
      "title" in subType &&
      (allSubTypes.find(
        type =>
          type.sub &&
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          (type.sub.title === subType.title || type.sub === subType.title)
      ) ||
        !subType.title)
    )
      setAllowColorSelect(false);
    else setAllowColorSelect(true);
  }, [subType]);

  useEffect(() => {
    setLocations(
      locations.map(l =>
        l.type.main === Category.SETTLING
          ? { ...l, switched: allSettlingsSelected }
          : l
      )
    );
  }, [allSettlingsSelected]);

  useEffect(() => {
    setLocations(
      locations.map(l =>
        l.type.main === Category.COMPETITION
          ? { ...l, switched: allCompetitionsSelected }
          : l
      )
    );
  }, [allCompetitionsSelected]);

  useEffect(() => {
    setSelectedCategories(
      allSubTypes.map(st =>
        typeof st.sub === "object" ? st.sub.title : st.sub
      )
    );
  }, []);

  useEffect(() => {
    if (!changedItemLocation || !changedItemFormattedAddress) {
      setEditLocationForMap(null);
      return;
    }
    if (
      !isEqual(editLocation, {
        ...changedItemLocation,
        formatted_address: changedItemFormattedAddress
      })
    )
      setEditLocationForMap({
        ...changedItemLocation,
        formatted_address: changedItemFormattedAddress
      });
  }, [changedItemLocation]);

  useEffect(() => {
    if (!editLocation) return;
    setChangedItemLocation({
      location: {
        lat: editLocation.location.lat,
        lng: editLocation.location.lng
      }
    });
    setChangedItemFormattedAddress(editLocation.formatted_address);
  }, [editLocation]);

  useEffect(() => {
    setSelectedCustomerId("");
  }, [activeCategory]);

  const handleSetType = (e: ChangeEvent<HTMLInputElement>) => {
    setType(e.target.value);
    if (e.target.value === Category.COMPETITION) {
      setSubType({ title: "", color: "" });
    } else if (e.target.value === Category.CUSTOMERS) {
      setSubType({
        tonnage: 0,
        category: "",
        materials: [{ tonnage: 0, category: "", id: v4() }],
        winner_first_level: "",
        winner_second_level: null,
        submiss_date: "",
        object_number: null,
        construction_time: {
          from: "",
          to: ""
        },
        nearest_neighbours: [],
        __typename: Category.CUSTOMERS
      });
    }
  };

  const handleSetChangedType = (value: string) => {
    setChangedItemType(value);
    if (value === Category.COMPETITION) {
      setChangedItemSubType({ title: "", color: "" });
    } else if (value === Category.CUSTOMERS) {
      setChangedItemSubType({
        tonnage: 0,
        category: "",
        materials: [{ tonnage: 0, category: "", id: v4() }],
        winner_first_level: "",
        winner_second_level: null,
        submiss_date: "",
        object_number: null,
        construction_time: {
          from: "",
          to: ""
        },
        nearest_neighbours: [],
        __typename: Category.CUSTOMERS
      });
    }
  };

  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const handleSave = async () => {
    if (!description) return;
    if (
      type === Category.COMPETITION &&
      subType &&
      "color" in subType &&
      "title" in subType
    ) {
      if (!subType.color) {
        const randomColor = getRandomColor();
        await onSave(description, {
          main: type,
          sub: {
            title: subType.title,
            color: randomColor,
            __typename: Category.COMPETITION
          }
        });
      } else {
        await onSave(description, {
          main: type,
          sub: {
            title: subType.title,
            color: subType.color,
            __typename: Category.COMPETITION
          }
        });
      }
    } else if (
      subType &&
      type === Category.CUSTOMERS &&
      "tonnage" in subType &&
      "category" in subType
    ) {
      await onSave(description, { main: type, sub: subType });
    } else {
      await onSave(description, { main: type });
    }
    setDescription("");
    setSubType(null);
    setType(Category.SETTLING);
  };

  const handleItemSwitch = (description: string) => {
    setLocations(
      locations.map(l =>
        l.description === description ? { ...l, switched: !l.switched } : l
      )
    );
  };

  const handleSwitchAllOfSubType = (subtype: string) => {
    if (selectedCategories.includes(subtype)) {
      setSelectedCategories(selectedCategories.filter(cat => cat !== subtype));
      setLocations(prev =>
        prev.map(loc => {
          if (!loc.type.sub) return loc;
          if (
            (typeof loc.type.sub === "string" && loc.type.sub === subtype) ||
            (typeof loc.type.sub === "object" &&
              "title" in loc.type.sub &&
              loc.type.sub.title === subtype)
          ) {
            return { ...loc, switched: false };
          } else return loc;
        })
      );
    } else {
      setSelectedCategories(selectedCategories.concat(subtype));
      setLocations(prev =>
        prev.map(loc => {
          if (!loc.type.sub) return loc;
          if (
            (typeof loc.type.sub === "string" && loc.type.sub === subtype) ||
            (typeof loc.type.sub === "object" &&
              "title" in loc.type.sub &&
              loc.type.sub.title === subtype)
          ) {
            return { ...loc, switched: true };
          } else return loc;
        })
      );
    }
  };

  const handleOpenEdit = (id: string) => {
    setItemIdToEdit(id);
  };

  const handleCancelEdit = () => {
    setItemIdToEdit(null);
    setChangedItemDesc(null);
    setChangedItemType(null);
    setChangedItemLocation(null);
    setChangedItemFormattedAddress(null);
    setEditLocationForMap(null);
    setCustomerOwnerEditFilter("");
    setChangedItemSubType(null);
  };

  const handleSaveChanges = async () => {
    if (
      !itemIdToEdit ||
      !changedItemDesc ||
      !changedItemType ||
      !changedItemFormattedAddress ||
      !changedItemLocation ||
      !changedItemSubType
    ) {
      return;
    } else {
      await onSaveChanges(
        itemIdToEdit,
        changedItemDesc,
        {
          main: changedItemType,
          sub: changedItemSubType
        },
        changedItemLocation,
        changedItemFormattedAddress
      );
      setItemIdToEdit(null);
      setChangedItemDesc(null);
      setChangedItemType(null);
      setChangedItemSubType(null);
      setChangedItemFormattedAddress(null);
      setChangedItemLocation(null);
    }
  };

  const filteredList = locations
    ? locations.filter(i => i.type.main === activeCategory)
    : null;

  const allSubTypes = (() => {
    // return Array.from(new Set(locations.map(i => i.type).filter(i => i.sub)));
    const types: {
      main: string;
      sub:
        | {
            title: string;
            color: string;
          }
        | string;
    }[] = [];
    locations
      .map(i => i.type)
      .filter(t => t.sub !== undefined)
      .forEach(type => {
        if (
          !types.find(t => {
            if (typeof type.sub === "string" && typeof t.sub === "string") {
              return type.sub === t.sub;
            } else {
              // @ts-ignore
              return type.sub.title === t.sub.title;
            }
          })
        ) {
          // @ts-ignore
          types.push(type);
        }
      });
    return types;
  })();

  const groupedCompetitionItems = useMemo(() => {
    if (!locations) return [];
    const withNoCat: {
      subtype: string;
      items: Location[];
    } = {
      subtype: "Keine Kategorie",
      items: []
    };
    return allSubTypes
      .map(s => ({
        subtype: s.sub,
        items: locations.filter(loc => {
          if (loc.type.main !== "competition") return false;
          if (!loc.type.sub) {
            withNoCat.items.push(loc);
            return false;
          }
          if (typeof loc.type.sub === "string" && typeof s.sub === "string") {
            return loc.type.sub === s.sub;
            // @ts-ignore
          } else return loc.type.sub.title === s.sub.title;
        })
      }))
      .concat(withNoCat)
      .filter(i => i.items.length && i.subtype !== "Keine Kategorie");
  }, [
    locations,
    itemIdToEdit,
    changedItemSubType,
    changedItemType,
    changedItemFormattedAddress,
    changedItemDesc
  ]);

  const filteredEditSubtypes = useMemo(
    () =>
      allSubTypes.filter(t => {
        if (
          changedItemSubType &&
          typeof t.sub === "string" &&
          "title" in changedItemSubType
        ) {
          return t.sub.includes(changedItemSubType.title);
        } else if (
          changedItemSubType &&
          typeof t.sub === "object" &&
          "title" in changedItemSubType &&
          "title" in t.sub
        ) {
          return t.sub.title.includes(changedItemSubType.title);
        }
      }),
    [allSubTypes, changedItemSubType]
  );

  const locationsWithoutCustomers: Owner[] = locations
    .filter(l => {
      return (
        l.type.main === Category.SETTLING ||
        l.type.main === Category.COMPETITION
      );
    })
    .map(l => ({
      location: l.location,
      formatted_address: l.formatted_address,
      description: l.description,
      type: l.type.main as "competition" | "settling",
      id: l.id
    }));

  const filteredEditWithoutCustomers: Owner[] = locationsWithoutCustomers.filter(
    l => l.description.includes(customerOwnerEditFilter)
  );

  const handleSetCreateAutoCompleteRef = useCallback(
    (node: HTMLInputElement) => {
      setAutoCompleteRef(node);
    },
    []
  );

  const handleSetEditAutoCompleteRef = useCallback((node: HTMLInputElement) => {
    setEditAutoCompleteRef(node);
  }, []);

  const handleStartCustomerCreation = () => {
    setChangedItemDesc("");
    setChangedItemFormattedAddress("");
    setChangedItemSubType({
      tonnage: 0,
      category: "",
      materials: [{ tonnage: 0, category: "", id: v4() }],
      winner_first_level: "",
      winner_second_level: null,
      submiss_date: "",
      object_number: null,
      construction_time: {
        from: "",
        to: ""
      },
      nearest_neighbours: [],
      __typename: Category.CUSTOMERS
    });
    setItemIdToEdit("");
    setShowCreationForm(true);
  };

  const handleSaveCustomer = async () => {
    if (
      !changedItemFormattedAddress ||
      !changedItemDesc ||
      !changedItemLocation
    )
      return;
    const newCustomer = {
      description: changedItemDesc,
      formatted_address: changedItemFormattedAddress,
      location: changedItemLocation.location,
      type: {
        main: Category.CUSTOMERS,
        sub: changedItemSubType as CustomerSubType
      },
      created_at: new Date().toISOString()
    };

    const savedLocation = await db.collection("locations").add(newCustomer);
    const projectName = process.env.REACT_APP_PROJECT_NAME || "";

    if (user)
      mp.track("ACTION", {
        Project_Name: projectName,
        User: user.email,
        Action_Type: "save",
        Customer: newCustomer.description,
        Customer_Type: newCustomer.type.main,
        Date: new Date(),
        Month_Year: new Intl.DateTimeFormat("en-GB", {
          month: "numeric",
          year: "numeric"
        }).format(new Date())
      });

    const stateCustomer = {
      ...newCustomer,
      id: savedLocation.id,
      switched: true
    };

    setLocations(prev => [...prev, stateCustomer]);
    setShowCreationForm(false);
    handleCancelEdit();
  };

  useEffect(() => {
    if (
      changedItemSubType &&
      "title" in changedItemSubType &&
      changedItemSubType.title &&
      filteredEditSubtypes.length &&
      !editModalOpening &&
      !showEditTypeDropDown
    ) {
      setShowEditTypeDropDown(true);
    } else setShowEditTypeDropDown(false);
  }, [changedItemSubType]);

  useEffect(() => {
    if (coordsToShowRoute) {
      cancelCreation();
    }
  }, [coordsToShowRoute]);

  useEffect(() => {
    if (showCreationForm) {
      handleSetCoordsToShowRoute(null);
    }
  }, [showCreationForm]);

  return (
    <StyledSidebar
      className="bg-light shadow position-relative"
      isTableView={activeCategory === Category.CUSTOMERS}
      tableView={tableView}
    >
      <Header
        activeCategory={activeCategory}
        companyName={companyName}
        handleStartCustomerCreation={handleStartCustomerCreation}
        setActiveCategory={setActiveCategory}
        setShowMenu={setShowMenu}
        showMenu={showMenu}
      />
      {activeCategory !== Category.CUSTOMERS && (
        <>
          <div
            className="w-100 position-relative"
            style={{
              height: 35,
              overflow: "hidden",
              marginTop: "7vh"
            }}
          >
            <input
              className="form-control form-control-sm w-100 h-100 pr-5"
              ref={handleSetCreateAutoCompleteRef}
              type="text"
              disabled={!apiReady}
              placeholder="Adresse eingeben"
              value={inputQuery}
              onChange={e => onInputChange(e.target.value)}
              aria-label="Search"
            />
            <SearchButton className="border-left">
              <FontAwesomeIcon
                icon={faSearch}
                style={{
                  right: 15,
                  cursor: "pointer"
                }}
                onClick={onSearch}
              />
            </SearchButton>
          </div>
          {showCreationForm && (
            <CreationForm
              allowColorSelect={allowColorSelect}
              apiReady={apiReady}
              allSubTypes={allSubTypes}
              cancelCreation={cancelCreation}
              companyName={companyName}
              description={description}
              setDescription={setDescription}
              handleSave={handleSave}
              handleSetType={handleSetType}
              setSubType={setSubType}
              setType={setType}
              subType={subType}
              type={type}
            />
          )}
        </>
      )}

      {filteredList && activeCategory !== Category.CUSTOMERS && (
        <StyledList
          className="mt-2 w-100"
          style={{
            overflowY: "scroll",
            overflowX: "hidden",
            height: `calc(100vh - 7vh - 30px  ${
              showCreationForm ? "- 193px" : ""
            })`
          }}
        >
          {
            <div className="position-relative w-100 d-flex align-items-center py-2 border-bottom">
              <input
                type="checkbox"
                id={activeCategory + "-select"}
                className="mr-2"
                checked={
                  activeCategory === Category.SETTLING
                    ? allSettlingsSelected
                    : allCompetitionsSelected
                }
                style={{ cursor: "pointer" }}
                onChange={() => {
                  if (activeCategory === Category.SETTLING)
                    setAllSettlingsSelected(!allSettlingsSelected);
                  else {
                    if (!allCompetitionsSelected) {
                      setSelectedCategories(
                        allSubTypes.map(st =>
                          typeof st.sub === "object" ? st.sub.title : st.sub
                        )
                      );
                    } else {
                      setSelectedCategories([]);
                    }
                    setAllCompetitionsSelected(!allCompetitionsSelected);
                  }
                }}
              />
              <div
                style={{
                  fontWeight: 700,
                  fontSize: "0.9rem"
                }}
              >
                alle auswählen
              </div>
            </div>
          }
          {activeCategory === Category.SETTLING &&
            filteredList.map(i =>
              itemIdToEdit === i.id && changedItemDesc && changedItemType ? (
                <EditLocationForm
                  allSubTypes={allSubTypes}
                  apiReady={apiReady}
                  changedItemDesc={changedItemDesc}
                  changedItemFormattedAddress={changedItemFormattedAddress}
                  changedItemSubType={changedItemSubType}
                  changedItemType={changedItemType}
                  customerOwnerEditFilter={customerOwnerEditFilter}
                  filteredEditSubtypes={filteredEditSubtypes}
                  filteredEditWithoutCustomers={filteredEditWithoutCustomers}
                  handleCancelEdit={handleCancelEdit}
                  handleSaveChanges={handleSaveChanges}
                  handleSetChangedType={handleSetChangedType}
                  item={i}
                  materialEditIdToShowDropdown={materialEditIdToShowDropdown}
                  setChangedItemDesc={setChangedItemDesc}
                  setChangedItemFormattedAddress={
                    setChangedItemFormattedAddress
                  }
                  setChangedItemSubType={setChangedItemSubType}
                  setCustomerOwnerEditFilter={setCustomerOwnerEditFilter}
                  setMaterialEditIdToShowDropdown={
                    setMaterialEditIdToShowDropdown
                  }
                  setRef={handleSetEditAutoCompleteRef}
                  setShowEditOwnerDropDown={setShowEditOwnerDropDown}
                  setShowEditTypeDropDown={setShowEditTypeDropDown}
                  showEditOwnerDropDown={showEditOwnerDropDown}
                  showEditTypeDropDown={showEditTypeDropDown}
                  key={i.id}
                />
              ) : (
                <DataRow
                  key={i.id}
                  handleItemSwitch={handleItemSwitch}
                  handleOpenEdit={handleOpenEdit}
                  item={i}
                  itemIdToShowOptions={itemIdToShowOptions}
                  onDelete={onDelete}
                  setEditModalOpening={setEditModalOpening}
                  setItemIdToShowOptions={setItemIdToShowOptions}
                />
              )
            )}
          {activeCategory === Category.COMPETITION &&
            groupedCompetitionItems.map((it, idx) => (
              <GroupItem
                key={idx}
                groupItem={it}
                itemIdToEdit={itemIdToEdit}
                selectedCategories={selectedCategories}
                handleSwitchAllOfSubType={handleSwitchAllOfSubType}
                allSubTypes={allSubTypes}
                apiReady={apiReady}
                changedItemDesc={changedItemDesc as string}
                changedItemFormattedAddress={changedItemFormattedAddress}
                changedItemSubType={changedItemSubType}
                changedItemType={changedItemType as string}
                customerOwnerEditFilter={customerOwnerEditFilter}
                filteredEditSubtypes={filteredEditSubtypes}
                filteredEditWithoutCustomers={filteredEditWithoutCustomers}
                handleCancelEdit={handleCancelEdit}
                handleSaveChanges={handleSaveChanges}
                handleSetChangedType={handleSetChangedType}
                materialEditIdToShowDropdown={materialEditIdToShowDropdown}
                setChangedItemDesc={setChangedItemDesc}
                setChangedItemFormattedAddress={setChangedItemFormattedAddress}
                setChangedItemSubType={setChangedItemSubType}
                setCustomerOwnerEditFilter={setCustomerOwnerEditFilter}
                setMaterialEditIdToShowDropdown={
                  setMaterialEditIdToShowDropdown
                }
                setRef={handleSetEditAutoCompleteRef}
                setShowEditOwnerDropDown={setShowEditOwnerDropDown}
                setShowEditTypeDropDown={setShowEditTypeDropDown}
                showEditOwnerDropDown={showEditOwnerDropDown}
                showEditTypeDropDown={showEditTypeDropDown}
                handleItemSwitch={handleItemSwitch}
                handleOpenEdit={handleOpenEdit}
                itemIdToShowOptions={itemIdToShowOptions}
                onDelete={onDelete}
                setEditModalOpening={setEditModalOpening}
                setItemIdToShowOptions={setItemIdToShowOptions}
              />
            ))}
        </StyledList>
      )}
      {filteredList && activeCategory === Category.CUSTOMERS && (
        <div
          className="position-relative w-100"
          style={{
            marginTop: 60,
            opacity: showCreationForm ? "0.5" : "1",
            pointerEvents: showCreationForm ? "none" : "all"
          }}
        >
          <CustomersTable
            locations={filteredList}
            onChange={onSaveChanges}
            handleSetCoordsToShowRoute={handleSetCoordsToShowRoute}
            coordsToShowRoute={coordsToShowRoute}
            handleRowClick={handleCustomerTableRowClick}
            selectedCustomerId={selectedCustomerId}
            itemIdToEdit={itemIdToEdit}
            setItemIdToEdit={setItemIdToEdit}
            showCreationForm={showCreationForm}
            showDistList={showDistList}
            setShowDistList={setShowDistList}
            tableView={tableView}
          />
        </div>
      )}
      {activeCategory === Category.CUSTOMERS && showCreationForm && (
        <CustomerEditForm
          itemIdToEdit={itemIdToEdit || ""}
          changedItemDesc={changedItemDesc || ""}
          changedItemFormattedAddress={changedItemFormattedAddress || ""}
          changedItemSubType={changedItemSubType as CustomerSubType}
          setChangedItemLocation={setChangedItemLocation}
          setChangedItemFormattedAddress={setChangedItemFormattedAddress}
          setChangedItemDesc={setChangedItemDesc}
          setChangedItemSubType={
            setChangedItemSubType as React.Dispatch<
              React.SetStateAction<CustomerSubType | null>
            >
          }
          filteredEditWithoutCustomers={filteredEditWithoutCustomers}
          maps={maps}
          apiReady={apiReady}
          cancelEdit={() => {
            setShowCreationForm(false);
            handleCancelEdit();
          }}
          saveChanges={handleSaveCustomer}
          style={{ right: 10 }}
        />
      )}
      {itemIdToEdit && activeCategory === Category.CUSTOMERS && (
        <CustomerEditForm
          itemIdToEdit={itemIdToEdit}
          locations={locations}
          changedItemDesc={changedItemDesc}
          changedItemFormattedAddress={changedItemFormattedAddress}
          changedItemSubType={changedItemSubType as CustomerSubType}
          setChangedItemLocation={setChangedItemLocation}
          setChangedItemFormattedAddress={setChangedItemFormattedAddress}
          setChangedItemDesc={setChangedItemDesc}
          setChangedItemSubType={
            setChangedItemSubType as React.Dispatch<
              React.SetStateAction<CustomerSubType | null>
            >
          }
          filteredEditWithoutCustomers={locationsWithoutCustomers}
          maps={maps}
          apiReady={apiReady}
          deleteCustomer={onDelete}
          cancelEdit={handleCancelEdit}
          saveChanges={handleSaveChanges}
        />
      )}

      {showDistList && (
        <DistanceList
          customerRadius={customerRadius}
          settlingRadius={settlingRadius}
          infoGroups={infoGroups}
          locations={locations}
          handleSetCoordsToShowRoute={handleSetCoordsToShowRoute}
          coordsToShowRoute={coordsToShowRoute}
          setShowDistList={setShowDistList}
        />
      )}

      <button
        className="btn btn-outline-danger position-absolute"
        style={{
          top: 15,
          left: "calc(100% + 15px)",
          zIndex: 300,
          fontWeight: 600,
          fontSize: ".9rem"
        }}
        onClick={() => {
          logOut().then(() => {
            showModal({ message: "Du bist jetzt abgemeldet", type: "warning" });
          });
        }}
      >
        Logout
      </button>
    </StyledSidebar>
  );
};

export default Sidebar;
