import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { UserContext } from "../context/user";
import { Redirect } from "react-router-dom";
import {
  signInWithEmailAndPassword,
  signInWithGoogle,
  signUpWithEmailAndPassword
} from "../services/auth";
import logo from "../assets/solongo.png";
import { useModal } from "../context/ModalContext";

const StyledAuth = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

enum AuthMode {
  LOGIN,
  REGISTER
}

const Auth = () => {
  const user = useContext(UserContext);
  const { showModal } = useModal();
  const [redirect, setredirect] = useState<string | null>(null);
  const [authMode, setAuthMode] = useState<AuthMode>(AuthMode.LOGIN);
  const [formData, setFormData] = useState<{ [key: string]: string }>({
    email: "",
    password: "",
    name: "",
    surname: ""
  });

  useEffect(() => {
    if (user) {
      setredirect("/main");
    }
  }, [user]);

  useEffect(() => {
    setFormData({});
  }, [authMode]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value.trim() });
  };

  const handleSubmit = async () => {
    const creds = formData as {
      email: string;
      password: string;
      name?: string;
      surname: string;
    };
    switch (authMode) {
      case LOGIN:
        try {
          await signInWithEmailAndPassword(creds);
        } catch (error) {
          console.error(error);
          showModal({
            message: "Einloggen fehlgeschlagen",
            type: "alert"
          });
        }
        break;
      case REGISTER:
        try {
          await signUpWithEmailAndPassword(creds);
          console.log("registered");
          setAuthMode(LOGIN);
        } catch (error) {
          console.error(error);
          showModal({
            message: "Anmeldung fehlgeschlagen",
            type: "alert"
          });
        }
        break;
      default:
        break;
    }
  };

  const { LOGIN, REGISTER } = AuthMode;

  return (
    <StyledAuth className="bg-dark">
      {redirect && <Redirect to={redirect} />}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: 400,
          alignItems: "center"
        }}
      >
        <img
          src={logo}
          alt="Solongo Logo"
          style={{ marginBottom: 50, width: 200 }}
        />
        <form
          className="w-100"
          onSubmit={e => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <div className="form-group w-100 d-flex flex-column align-items-center mb-4">
            {authMode === REGISTER && (
              <>
                <input
                  type="text"
                  name="name"
                  required={true}
                  className="form-control mb-2"
                  placeholder="Name"
                  value={formData.name || ""}
                  onChange={handleChange}
                />
                <input
                  type="surname"
                  name="surname"
                  required={true}
                  className="form-control mb-2"
                  placeholder="Nachname"
                  value={formData.surname || ""}
                  onChange={handleChange}
                />
              </>
            )}
            <input
              type="email"
              name="email"
              className="form-control mb-2"
              aria-describedby="emailHelp"
              placeholder="Email"
              value={formData.email || ""}
              onChange={handleChange}
            />
            <input
              type="password"
              name="password"
              className="form-control mb-3"
              placeholder="Password"
              value={formData.password || ""}
              onChange={handleChange}
            />

            {authMode === LOGIN ? (
              <button type="submit" className="btn btn-primary w-50">
                Einloggen
              </button>
            ) : (
              <button type="submit" className="btn btn-primary w-50">
                Neu registrieren
              </button>
            )}
          </div>
        </form>
        <div className="position-relative w-100 mb-4">
          <div className="w-100 bg-light rounded" style={{ height: 2 }} />
          <div
            className="position-absolute bg-dark text-light px-3"
            style={{
              left: "50%",
              top: "50%",
              transform: "translate(-50%,-50%)"
            }}
          >
            oder
          </div>
        </div>
        <div>
          {authMode === LOGIN ? (
            <button
              className="btn btn-primary bg-white border-0 shadow text-dark px-5 py-2 mb-2 w-100"
              onClick={() => setAuthMode(REGISTER)}
            >
              Registrieren
            </button>
          ) : (
            <button
              className="btn btn-primary bg-white border-0 shadow text-dark px-5 py-2 mb-2 w-100"
              onClick={() => setAuthMode(LOGIN)}
            >
              Einloggen
            </button>
          )}
          <button
            className="btn btn-primary d-flex align-items-center bg-white border-0 shadow text-dark px-5 py-2"
            onClick={signInWithGoogle}
          >
            <img
              src="https://img.icons8.com/color/96/000000/google-logo.png"
              alt="google icon"
              style={{ width: 30 }}
            />
            <span style={{ fontSize: "1.1rem", marginLeft: 10 }}>
              Mit Google fortsetzen
            </span>
          </button>
        </div>
      </div>
    </StyledAuth>
  );
};

export default Auth;
