import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import { DropdownItem } from ".";
import { CustomerSubType, Location, Owner } from "../../types";
import { Category } from "../../Views/Main";

export type EditLocationFormProps = {
  item: Location;
  setRef: (node: HTMLInputElement) => void;
  apiReady: boolean;
  changedItemFormattedAddress: string | null;
  setChangedItemFormattedAddress: (value: string | null) => void;
  changedItemDesc: string;
  setChangedItemDesc: (value: string | null) => void;
  changedItemType: string;
  handleSetChangedType: (value: string) => void;
  changedItemSubType:
    | CustomerSubType
    | {
        title: string;
        color: string;
      }
    | null;
  setChangedItemSubType: (
    value:
      | CustomerSubType
      | {
          title: string;
          color: string;
        }
      | null
  ) => void;
  materialEditIdToShowDropdown: string;
  setMaterialEditIdToShowDropdown: (value: string) => void;
  customerOwnerEditFilter: string;
  setCustomerOwnerEditFilter: (value: string) => void;
  setShowEditOwnerDropDown: (value: React.SetStateAction<boolean>) => void;
  showEditOwnerDropDown: boolean;
  filteredEditWithoutCustomers: Owner[];
  showEditTypeDropDown: boolean;
  setShowEditTypeDropDown: (value: boolean) => void;
  allSubTypes: {
    main: string;
    sub:
      | {
          title: string;
          color: string;
        }
      | string;
  }[];
  filteredEditSubtypes: {
    main: string;
    sub:
      | {
          title: string;
          color: string;
        }
      | string;
  }[];
  handleCancelEdit: () => void;
  handleSaveChanges: () => Promise<void>;
};

const EditLocationForm = ({
  item,
  setRef,
  apiReady,
  changedItemDesc,
  setChangedItemDesc,
  changedItemFormattedAddress,
  setChangedItemFormattedAddress,
  changedItemType,
  handleSetChangedType,
  changedItemSubType,
  materialEditIdToShowDropdown,
  setChangedItemSubType,
  setMaterialEditIdToShowDropdown,
  customerOwnerEditFilter,
  setShowEditOwnerDropDown,
  showEditOwnerDropDown,
  setCustomerOwnerEditFilter,
  filteredEditWithoutCustomers,
  allSubTypes,
  filteredEditSubtypes,
  setShowEditTypeDropDown,
  showEditTypeDropDown,
  handleCancelEdit,
  handleSaveChanges
}: EditLocationFormProps) => {
  return (
    <div className="position-relative  w-100 mt-2 border p-2">
      <div className="mb-1 position-relative">
        <input
          type="text"
          ref={setRef}
          className="form-control w-75 position-relative"
          style={{ fontSize: ".9rem" }}
          disabled={!apiReady}
          placeholder="Adresse eingeben"
          value={changedItemFormattedAddress || ""}
          onChange={e => setChangedItemFormattedAddress(e.target.value)}
          aria-label="Search"
        />
      </div>

      <input
        type="text"
        className="form-control w-75"
        style={{ fontSize: ".9rem" }}
        value={changedItemDesc}
        onChange={e => setChangedItemDesc(e.target.value)}
      />

      <div className="mt-2 pl-1">
        <input
          type="radio"
          name="type_edit"
          id="Niederlassung"
          value={Category.SETTLING}
          checked={changedItemType === Category.SETTLING}
          onChange={e => handleSetChangedType(e.target.value)}
          className="mr-2"
        />
        <label htmlFor="Niederlassung">Niederlassung</label>
        <br />

        <input
          type="radio"
          name="type_edit"
          id="Kunden"
          value={Category.CUSTOMERS}
          checked={changedItemType === Category.CUSTOMERS}
          onChange={e => handleSetChangedType(e.target.value)}
          className="mr-2"
        />
        <label htmlFor="Kunden">Kunden</label>
        {!!(
          changedItemType === Category.CUSTOMERS &&
          changedItemSubType &&
          "__typename" in changedItemSubType &&
          changedItemSubType.__typename === Category.CUSTOMERS
        ) && (
          <>
            <div
              style={{
                width: "100%",
                height: 1,
                marginBottom: "10px",
                backgroundColor: "rgb(33, 37, 41, 0.1)"
              }}
            />
            {changedItemSubType.materials.map((m, key) => (
              <Fragment key={key}>
                <div className="w-75 position-relative mb-2">
                  <input
                    className="w-100 form-control"
                    style={{ height: 35 }}
                    placeholder="Tonnage"
                    type="number"
                    value={m.tonnage === 0 ? "null" : m.tonnage}
                    min={0}
                    onChange={e =>
                      setChangedItemSubType({
                        ...changedItemSubType,
                        materials: changedItemSubType.materials.map(mat =>
                          mat.id === m.id
                            ? { ...mat, tonnage: +e.target.value }
                            : mat
                        )
                      })
                    }
                  />
                </div>
                <div className="w-75 position-relative mb-2">
                  <input
                    className="w-100 form-control"
                    style={{ height: 35 }}
                    placeholder="Kategorie"
                    type="text"
                    value={m.category}
                    readOnly
                  />
                  <FontAwesomeIcon
                    icon={
                      materialEditIdToShowDropdown === m.id
                        ? faChevronUp
                        : faChevronDown
                    }
                    style={{
                      position: "absolute",
                      right: 10,
                      top: 10,
                      cursor: "pointer"
                    }}
                    onClick={() =>
                      setMaterialEditIdToShowDropdown(
                        materialEditIdToShowDropdown === m.id ? "" : m.id
                      )
                    }
                  />
                  {materialEditIdToShowDropdown === m.id && (
                    <div
                      style={{
                        position: "absolute",
                        top: "100%",
                        zIndex: 100,
                        maxHeight: 300,
                        overflowY: "scroll",
                        left: 0,
                        right: 0
                      }}
                    >
                      {[
                        "",
                        "Keine",
                        "Tragschicht",
                        "Binder",
                        "Decke",
                        "SMA",
                        "Gußaphalt",
                        "Tragdeckschicht",
                        "Sonstiges"
                      ].map((v, index) => (
                        <DropdownItem
                          key={index}
                          className="p-1 bg-white border-bottom border-right border-left"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setChangedItemSubType({
                              ...changedItemSubType,
                              materials: changedItemSubType.materials.map(mat =>
                                mat.id === m.id ? { ...mat, category: v } : mat
                              )
                            });
                            setMaterialEditIdToShowDropdown("");
                          }}
                        >
                          {!v ? "Kategorie" : v}
                        </DropdownItem>
                      ))}
                    </div>
                  )}
                </div>
              </Fragment>
            ))}
            <input
              className="w-75 my-1 form-control"
              style={{ height: 35 }}
              placeholder="Gewinner 1"
              type="text"
              value={changedItemSubType.winner_first_level}
              onChange={e =>
                setChangedItemSubType({
                  ...changedItemSubType,
                  winner_first_level: e.target.value
                })
              }
            />
            <div className="w-75 position-relative">
              <input
                className="w-100 form-control"
                style={{ height: 35 }}
                placeholder="Gewinner 2"
                type="text"
                value={customerOwnerEditFilter}
                onChange={e => {
                  setCustomerOwnerEditFilter(e.target.value);
                  if (e.target.value.length) {
                    setShowEditOwnerDropDown(true);
                  } else {
                    setShowEditOwnerDropDown(false);
                  }
                }}
              />
              <FontAwesomeIcon
                icon={showEditOwnerDropDown ? faChevronUp : faChevronDown}
                style={{
                  position: "absolute",
                  right: 10,
                  top: 10,
                  cursor: "pointer"
                }}
                onClick={() => setShowEditOwnerDropDown(!showEditOwnerDropDown)}
              />
              {showEditOwnerDropDown && (
                <div
                  style={{
                    position: "absolute",
                    top: "100%",
                    zIndex: 100,
                    maxHeight: 300,
                    overflowY: "scroll",
                    left: 0,
                    right: 0
                  }}
                >
                  {filteredEditWithoutCustomers.map((owner, index) => (
                    <DropdownItem
                      key={index}
                      className="p-1 bg-white border-bottom border-right border-left"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setChangedItemSubType({
                          ...changedItemSubType,
                          winner_second_level: owner
                        });
                        setCustomerOwnerEditFilter(owner.description);
                        setShowEditOwnerDropDown(false);
                      }}
                    >
                      {owner.description}
                    </DropdownItem>
                  ))}
                </div>
              )}
            </div>
            <input
              type="number"
              placeholder="Objektnummer"
              className="form-control w-75 my-1"
              minLength={6}
              maxLength={6}
              value={changedItemSubType.object_number || ""}
              onChange={e =>
                setChangedItemSubType({
                  ...changedItemSubType,
                  object_number: !isNaN(+e.target.value) ? +e.target.value : 0
                })
              }
            />
            <input
              type="date"
              className="form-control w-75 my-1"
              value={changedItemSubType.submiss_date}
              onChange={e =>
                setChangedItemSubType({
                  ...changedItemSubType,
                  submiss_date: e.target.value
                })
              }
            />
            <div
              style={{
                width: "100%",
                height: 1,
                marginTop: "10px",
                backgroundColor: "rgb(33, 37, 41, 0.1)"
              }}
            />
          </>
        )}
        <br />

        <input
          type="radio"
          name="type_edit"
          id="Konkurrenz"
          value={Category.COMPETITION}
          checked={changedItemType === Category.COMPETITION}
          onChange={e => handleSetChangedType(e.target.value)}
          className="mr-2"
        />
        <label htmlFor="Konkurrenz">Konkurrenz</label>
        {!!(
          changedItemType === Category.COMPETITION &&
          changedItemSubType &&
          "title" in changedItemSubType &&
          "color" in changedItemSubType
        ) && (
          <>
            <div className="w-75 position-relative">
              <input
                className="w-100 form-control"
                style={{ height: 35 }}
                placeholder="Kategorie"
                type="text"
                value={changedItemSubType.title}
                onChange={e => {
                  setChangedItemSubType({
                    title: e.target.value,
                    color: ""
                  });
                }}
              />
              <FontAwesomeIcon
                icon={showEditTypeDropDown ? faChevronUp : faChevronDown}
                style={{
                  position: "absolute",
                  right: 10,
                  top: 10,
                  cursor: "pointer"
                }}
                onClick={() => {
                  setShowEditTypeDropDown(!showEditTypeDropDown);
                }}
              />
              {!!(allSubTypes.length && showEditTypeDropDown) && (
                <div
                  style={{
                    position: "absolute",
                    top: "100%",
                    zIndex: 100,
                    maxHeight: 300,
                    overflowY: "scroll",
                    left: 0,
                    right: 0
                  }}
                >
                  {filteredEditSubtypes.map((i, index) =>
                    i.main === Category.COMPETITION && i.sub ? (
                      <DropdownItem
                        key={index}
                        className="p-1 bg-white border-bottom border-right border-left"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (typeof i.sub === "string") {
                            const title = i.sub;
                            setChangedItemSubType({
                              title: title,
                              color: "#dc3545"
                            });
                          } else {
                            const item = i.sub as {
                              title: string;
                              color: string;
                            };
                            setChangedItemSubType({
                              title: item.title,
                              color: item.color
                            });
                          }
                          setImmediate(() => {
                            setShowEditTypeDropDown(false);
                          });
                        }}
                      >
                        {typeof i.sub === "string" ? i.sub : i.sub.title}
                      </DropdownItem>
                    ) : null
                  )}
                </div>
              )}
            </div>
            <div className="mt-1 w-75 position-relative">
              <input
                type="text"
                className="w-100 form-control"
                value={changedItemSubType.color}
                readOnly={true}
                placeholder="Farbe wählen"
                style={{ height: 35 }}
              />
              <input
                type="color"
                className="position-absolute"
                placeholder="Farbe wählen"
                value={changedItemSubType.color}
                style={{
                  height: 35,
                  width: 35,
                  right: 0,
                  top: 0,
                  cursor: "pointer"
                }}
                onChange={e =>
                  setChangedItemSubType({
                    title: changedItemSubType.title,
                    color: e.target.value
                  })
                }
              />
            </div>
          </>
        )}
      </div>
      <div className="d-flex w-100 justify-content-between mt-3">
        <button
          className="btn btn-outline-secondary"
          onClick={handleCancelEdit}
        >
          abbrechen
        </button>
        <button className="btn btn-outline-success" onClick={handleSaveChanges}>
          speichern
        </button>
      </div>
    </div>
  );
};

export default EditLocationForm;
