import React, { useEffect, useContext, useState } from "react";
import styled from "styled-components";
import UserProvider, { UserContext } from "./context/user";
import { MixPanel, MixPanelContext } from "./util/Mixpanel";
import { ModalProvider } from "./context/ModalContext";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import Login from "./Views/Auth";
import Main from "./Views/Main";

function App() {
  const user = useContext(UserContext);
  const [redirect, setRedirect] = useState<string | null>(null);

  useEffect(() => {
    document.title = process.env.REACT_APP_PROJECT_NAME + "-MapDistance";
  }, []);

  useEffect(() => {
    if (user === null) {
      setRedirect("/login");
    } else {
      setRedirect("/main");
    }
  }, [user]);

  const mp = MixPanel.getInstance(process.env.REACT_APP_MIXPANEL_TOKEN, {
    name: user?.email
  });

  return (
    <MixPanelContext.Provider value={{ mp }}>
      <ModalProvider>
        <UserProvider>
          <Router>
            {redirect && <Redirect to={redirect} />}
            <Switch>
              <Route exact path="/login" component={Login} />
              <Route exact path="/main" component={Main} />
            </Switch>
          </Router>
        </UserProvider>
      </ModalProvider>
    </MixPanelContext.Provider>
  );
}

export default App;
