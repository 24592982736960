import React from "react";
import { Location } from "../../types";
import OptionsBar from "./OptionsBar";

export type DataRowProps = {
  item: Location;
  handleItemSwitch: (description: string) => void;
  itemIdToShowOptions: string;
  setEditModalOpening: (value: React.SetStateAction<boolean>) => void;
  handleOpenEdit: (id: string) => void;
  onDelete: (id: string) => Promise<void>;
  setItemIdToShowOptions: (value: React.SetStateAction<string>) => void;
  hideColorBar?: boolean;
};

const DataRow = ({
  item,
  handleItemSwitch,
  handleOpenEdit,
  itemIdToShowOptions,
  onDelete,
  setEditModalOpening,
  setItemIdToShowOptions,
  hideColorBar
}: DataRowProps) => {
  return (
    <div
      className="position-relative w-100 d-flex align-items-center py-1 border-bottom"
      onMouseEnter={() => setItemIdToShowOptions(item.id)}
      onMouseLeave={() => setItemIdToShowOptions("")}
    >
      <input
        type="checkbox"
        name={item.id}
        id={item.description}
        value={item.description}
        checked={item.switched}
        className="mr-2"
        style={{ cursor: "pointer" }}
        onChange={() => handleItemSwitch(item.description)}
      />
      <div style={{ fontSize: "0.9rem" }}>{item.description}</div>
      {itemIdToShowOptions === item.id && (
        <OptionsBar
          handleDelete={() => onDelete(item.id)}
          handleOpenEdit={(e: any) => {
            e.stopPropagation();
            setEditModalOpening(true);
            setImmediate(() => {
              setEditModalOpening(false);
            });
            handleOpenEdit(item.id);
          }}
        />
      )}
      {!!(
        item.type.sub &&
        typeof item.type.sub !== "string" &&
        "color" in item.type.sub &&
        item.type.sub.color &&
        !hideColorBar
      ) && (
        <div
          style={{
            position: "absolute",
            right: 0,
            top: 0,
            bottom: 0,
            width: 5,
            backgroundColor: item.type.sub.color
          }}
        />
      )}
    </div>
  );
};

export default DataRow;
