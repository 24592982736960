/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  useState,
  createContext,
  useEffect,
  useContext,
  useRef
} from "react";

type ModalParams = {
  message: string;
  type: "succes" | "warning" | "alert" | "";
};

type ModalContext = {
  showModal: (params: ModalParams) => void;
};

const defaultValue: ModalContext = {
  showModal: ({ message = "", type = "" }) => {}
};

const ModalContext = createContext(defaultValue);

export const useModal = () => useContext(ModalContext);

export const ModalProvider: React.FC = ({ children }) => {
  const [modal, setModal] = useState<{
    show: boolean;
    message: string;
    colorClass: string;
  }>({
    show: false,
    message: "",
    colorClass: ""
  });
  const timeout = useRef<any>(null);

  useEffect(() => {
    if (!modal.show) return;
    timeout.current = setTimeout(() => {
      setModal({ show: false, message: "", colorClass: "" });
    }, 5000);
    return () => clearTimeout(timeout.current);
  }, [modal.show]);

  const showModal = (params: ModalParams) => {
    const { message, type } = params;
    setModal({
      show: true,
      message,
      colorClass:
        type === "succes"
          ? "bg-success"
          : type === "warning"
          ? "bg-warning"
          : "bg-danger"
    });
  };

  return (
    <ModalContext.Provider value={{ showModal }}>
      <div className="position-relative">
        {modal.show && (
          <div
            style={{
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              top: 10,
              right: 10,
              maxHeight: 120,
              maxWidth: 250,
              borderRadius: 5,
              padding: "5px 10px",
              zIndex: 10000
            }}
            className={modal.colorClass + " shadow"}
          >
            {modal.message}
          </div>
        )}
        {children}
      </div>
    </ModalContext.Provider>
  );
};
