import styled from "styled-components";
import GoogleMapReact from "google-map-react";
import React, { useEffect, useMemo, useState } from "react";
import Marker from "./Marker";
import { CustomerSubType, InfoGroup, Location } from "../../types";
import { isEqual } from "lodash";
import { Category } from "../../Views/Main";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

type MapAreaProps = {
  center: {
    lat: number;
    lng: number;
  };
  zoom: number;
  maps: any;
  map: any;
  setMapZoom: React.Dispatch<number>;
  handleApiLoaded: (map: any, maps: any) => void;
  locations: Location[];
  customerRadius: number;
  setSettlingRadius: React.Dispatch<React.SetStateAction<number>>;
  setCustomerRadius: React.Dispatch<React.SetStateAction<number>>;
  settlingRadius: number;
  selectedCustomerId: string;
  infoGroups: InfoGroup[];
  searchedLocation: {
    location: {
      lat: number;
      lng: number;
    };
    formatted_address: string;
  } | null;
  setSearchedLocation: React.Dispatch<
    React.SetStateAction<{
      location: {
        lat: number;
        lng: number;
      };
      formatted_address: string;
    } | null>
  >;
  editLocation: {
    location: {
      lat: number;
      lng: number;
    };
    formatted_address: string;
  } | null;
  setEditLocationForMap: (
    location: {
      location: {
        lat: number;
        lng: number;
      };
      formatted_address: string;
    } | null
  ) => void;
  groupsToShow: Category[];
  setGroupsToShow: React.Dispatch<React.SetStateAction<Category[]>>;
  activeCategory: string;
  tableView: "full" | "reduced";
  setTableView: React.Dispatch<React.SetStateAction<"full" | "reduced">>;
};

const StyledMapArea = styled.div`
  flex-grow: 1;
  position: relative;
`;

const MapArea = ({
  center,
  zoom,
  handleApiLoaded,
  locations,
  setMapZoom,
  maps,
  map,
  setCustomerRadius,
  setSettlingRadius,
  customerRadius,
  settlingRadius,
  selectedCustomerId,
  infoGroups,
  searchedLocation,
  setSearchedLocation,
  editLocation,
  setEditLocationForMap,
  groupsToShow,
  setGroupsToShow,
  activeCategory,
  setTableView,
  tableView
}: MapAreaProps) => {
  const [colorMode, setColorMode] = useState<"rgb" | "bw">("rgb");

  useEffect(() => {
    if (!map) return;
    const style = [
      { elementType: "geometry", stylers: [{ saturation: -100 }] }
    ];
    const mapType = new maps.StyledMapType(style, { name: "Greyscale" });
    map.mapTypes.set("grey", mapType);
  }, [map]);

  useEffect(() => {
    if (!map) return;
    if (colorMode === "bw") {
      map.setMapTypeId("grey");
    } else {
      map.setMapTypeId("roadmap");
    }
  }, [colorMode, map]);

  const filteredLocations = useMemo(() => {
    if (!selectedCustomerId || !infoGroups.length)
      return locations.filter(l =>
        groupsToShow.includes(l.type.main as Category)
      );
    const selectedCustomer = locations.find(i => i.id === selectedCustomerId);

    if (selectedCustomer && typeof selectedCustomer.type.sub === "object") {
      const subType = selectedCustomer.type.sub as CustomerSubType;
      return [
        selectedCustomer,
        ...subType.nearest_neighbours.map(
          n =>
            locations.find(
              l =>
                l.description === n.description &&
                isEqual(l.location, n.location)
            ) as Location
        ),
        ...locations.filter(i => {
          if (customerRadius === 0 || customerRadius === 0) return false;
          const igItem = infoGroups[0].distances.find(
            d => d.title.title === i.description
          );
          if (!igItem) return false;
          return (
            +igItem.distance <= customerRadius &&
            !subType.nearest_neighbours.find(
              n => igItem.title.title === n.description
            ) &&
            groupsToShow.includes(i.type.main as Category)
          );
        })
      ];
    }
    return [];
  }, [locations, customerRadius, selectedCustomerId, infoGroups, groupsToShow]);

  const switchButtons = [
    {
      title: "Niederlassungen",
      text: "NL",
      category: Category.SETTLING
    },
    {
      title: "Baustellen",
      text: "BS",
      category: Category.CUSTOMERS
    },
    {
      title: "Konkurrenz",
      text: "KO",
      category: Category.COMPETITION
    }
  ];

  return (
    <StyledMapArea>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyAM8Ciwgzts5Dp9I8_DEYJN3oTWjAsz3rE"
        }}
        defaultCenter={center}
        zoom={zoom}
        options={{
          backgroundColor: "#eeeeee",
          zoomControl: false,
          fullscreenControl: false
        }}
        yesIWantToUseGoogleMapApiInternals={true}
        onGoogleApiLoaded={({ map, maps }) => {
          handleApiLoaded(map, maps);
        }}
        onChange={e => setMapZoom(e.zoom)}
        onClick={e =>
          searchedLocation
            ? setSearchedLocation({
                ...searchedLocation,
                location: { lat: e.lat, lng: e.lng }
              })
            : editLocation
            ? setEditLocationForMap({
                ...editLocation,
                location: { lat: e.lat, lng: e.lng }
              })
            : null
        }
      >
        {!editLocation &&
          !searchedLocation &&
          filteredLocations.map(l =>
            l.switched ? (
              <Marker
                key={l.id}
                zoom={zoom}
                lat={l.location.lat}
                lng={l.location.lng}
                text={l.description}
                type={l.type}
                maps={maps}
                map={map}
                customerRadiur={customerRadius}
                settlingRadius={settlingRadius}
                selectedCustomerId={selectedCustomerId}
                locations={locations}
              />
            ) : null
          )}
        {searchedLocation && (
          <Marker
            zoom={zoom}
            maps={maps}
            map={map}
            type={{ main: "competition" }}
            lat={searchedLocation.location.lat}
            lng={searchedLocation.location.lng}
            text={searchedLocation.formatted_address}
            selectedCustomerId={selectedCustomerId}
            location={searchedLocation}
          />
        )}
        {editLocation && (
          <Marker
            zoom={zoom}
            maps={maps}
            map={map}
            type={{ main: "competition" }}
            lat={editLocation.location.lat}
            lng={editLocation.location.lng}
            text={editLocation.formatted_address}
            selectedCustomerId={selectedCustomerId}
            location={editLocation}
          />
        )}
      </GoogleMapReact>
      {activeCategory === Category.CUSTOMERS && (
        <button
          className={`btn btn-secondary
        } position-absolute`}
          style={{ top: 105, left: 10 }}
          onClick={() =>
            setTableView(tableView === "full" ? "reduced" : "full")
          }
        >
          <FontAwesomeIcon
            icon={tableView === "full" ? faArrowLeft : faArrowRight}
          />
        </button>
      )}
      <ul
        className="list-group position-absolute"
        style={{ bottom: 300, left: 10 }}
      >
        <input
          type="number"
          min={0}
          step={5}
          title="Umkreis Baustelle"
          className="list-group-item text-center p-0 mb-2"
          value={customerRadius}
          onChange={e => setCustomerRadius(+e.target.value)}
          style={{
            cursor: "pointer",
            height: 47,
            width: 47
          }}
        />
        <input
          type="number"
          min={0}
          step={5}
          title="Umkreis Mischanlagen"
          className="list-group-item text-center p-0"
          value={settlingRadius}
          onChange={e => setSettlingRadius(+e.target.value)}
          style={{
            cursor: "pointer",
            height: 47,
            width: 47
          }}
        />
      </ul>
      <ul
        className="list-group position-absolute"
        style={{ bottom: 150, left: 10 }}
      >
        {switchButtons.map(({ title, text, category }, key) => (
          <li
            key={key}
            className={`list-group-item list-group-item-action${
              groupsToShow.includes(category) ? " active" : ""
            }`}
            onClick={() => {
              groupsToShow.includes(category)
                ? setGroupsToShow(groupsToShow.filter(g => g !== category))
                : setGroupsToShow([...groupsToShow, category]);
            }}
            style={{ cursor: "pointer", padding: "6px 12px" }}
            title={title}
          >
            {text}
          </li>
        ))}
      </ul>
      <button
        className={`btn btn-${
          colorMode === "bw" ? "success" : "secondary"
        } position-absolute`}
        style={{ bottom: 10, left: 10 }}
        onClick={() => setColorMode(colorMode === "bw" ? "rgb" : "bw")}
      >
        {colorMode === "rgb" ? "SW" : "RGB"}
      </button>
    </StyledMapArea>
  );
};

export default MapArea;
