import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Category } from "../../Views/Main";

type HeaderProps = {
  showMenu: boolean;
  setShowMenu: (value: React.SetStateAction<boolean>) => void;
  companyName: string;
  activeCategory: string;
  setActiveCategory: (category: Category) => void;
  handleStartCustomerCreation: () => void;
};

const Header = ({
  showMenu,
  setShowMenu,
  companyName,
  activeCategory,
  setActiveCategory,
  handleStartCustomerCreation
}: HeaderProps) => {
  return (
    <div
      className="bg-secondary position-absolute d-flex align-items-center justify-content-center"
      style={{ height: "7vh", top: 0, left: 0, right: 0 }}
    >
      <div>
        <span
          tabIndex={0}
          className="position-absolute px-2"
          style={{
            fontSize: "1.6rem",
            left: 15,
            top: "52%",
            transform: "translateY(-50%)",
            backgroundColor: "transparent",
            boxShadow: "none",
            cursor: "pointer",
            color: "white"
          }}
          onClick={() => setShowMenu(!showMenu)}
        >
          <FontAwesomeIcon icon={faBars} />
        </span>
        {showMenu && (
          <ul
            className="position-absolute list-group shadow"
            style={{ top: "100%", left: 0, zIndex: 1001, borderRadius: 0 }}
          >
            {[
              { type: Category.CUSTOMERS, title: "Baustellen" },
              { type: Category.COMPETITION, title: "Konkurrenz" },
              {
                type: Category.SETTLING,
                title: companyName + " Niederlassungen"
              }
            ].map((cat, key) => (
              <li
                key={key}
                className={`list-group-item list-group-item-light list-group-item-action${
                  activeCategory === cat.type ? " active" : ""
                }`}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setActiveCategory(cat.type);
                  setShowMenu(false);
                }}
              >
                {cat.title[0].toUpperCase() + cat.title.slice(1)}
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="d-flex align-items-end justify-content-center position-relative">
        <span
          className="text-light mr-1"
          style={{
            lineHeight: 0.8,
            fontSize: "calc(1.5rem + .5vw)",
            userSelect: "none"
          }}
        >
          {companyName}
        </span>
        <span
          className="text-light"
          style={{ fontSize: "0.7rem", lineHeight: 0.8 }}
        >
          powered by solongo
        </span>
      </div>
      {activeCategory === Category.CUSTOMERS && (
        <button
          className="position-absolute btn btn-danger btn-sm"
          style={{ right: 15, top: "70%", transform: "translateY(-50%)" }}
          onClick={handleStartCustomerCreation}
        >
          Baustelle hinzufügen
        </button>
      )}
    </div>
  );
};

export default Header;
