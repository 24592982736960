import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { InfoGroup, Location } from "../../types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faTimes } from "@fortawesome/free-solid-svg-icons";
import { generateCSV } from "../../util/generateCsv";

type DistListProps = {
  customerRadius: number;
  settlingRadius: number;
  infoGroups: InfoGroup[];
  locations: Location[];
  handleSetCoordsToShowRoute: (
    newCoords: {
      origin: {
        lat: number;
        lng: number;
      };
      destination: {
        lat: number;
        lng: number;
      };
    } | null
  ) => void;
  coordsToShowRoute: {
    origin: {
      lat: number;
      lng: number;
    };
    destination: {
      lat: number;
      lng: number;
    };
  } | null;
  setShowDistList: React.Dispatch<React.SetStateAction<boolean>>;
};

const StyledDistList = styled.div`
  position: absolute;
  left: calc(100% + 10px);
  top: 45%;
  transform: translateY(-60%);
  height: 40vh;
  z-index: 3000;
  overflow-y: scroll;
  min-width: 380px;
  & > * {
    white-space: nowrap;
  }
`;

const DistList = ({
  customerRadius,
  infoGroups,
  handleSetCoordsToShowRoute,
  coordsToShowRoute,
  setShowDistList
}: DistListProps) => {
  const [currRowWithRoute, setCurrRowWithRoute] = useState<string | null>(null);

  useEffect(() => {
    if (coordsToShowRoute === null) setCurrRowWithRoute(null);
  }, [coordsToShowRoute]);

  const withoutUmlauts = (string: string) => {
    return string
      .replaceAll("ü", "ue")
      .replaceAll("ä", "ae")
      .replaceAll("ö", "oe")
      .replaceAll("Ä", "Ae")
      .replaceAll("Ü", "Ue")
      .replaceAll("Ö", "Oe");
  };

  const handleExportCsv = () => {
    const group = infoGroups[0];
    const uri = generateCSV(
      group.distances.map(d => [
        withoutUmlauts(d.title.title),
        withoutUmlauts(d.title.formatted_address.split(",").join(" ")),
        withoutUmlauts(
          d.shortDisatnce
            ? d.shortDisatnce === d.distance
              ? d.shortDisatnce
              : d.distance
            : d.distance
        )
      ]),
      [
        [
          withoutUmlauts(group.group_title.title),
          withoutUmlauts(
            group.group_title.formatted_address.split(",").join(" ")
          )
        ],
        ["Beschreibung", "Adresse", "Enfernung"]
      ]
    );
    const fileName =
      group.group_title.title.toUpperCase().split(" ").join("_") +
      "_Distances.csv";
    const link = document.createElement("a");
    link.setAttribute("href", uri);
    link.setAttribute("download", fileName);
    document.body.appendChild(link); // Required for FF

    link.click();
  };

  const filteredGroups = infoGroups.map(g => ({
    ...g,
    distances: [
      ...g.distances
        .sort((a: any, b: any) => a.distance - b.distance)
        .slice(0, 5),
      ...g.distances
        .sort((a: any, b: any) => a.distance - b.distance)
        .slice(5)
        .filter(l => +l.distance <= customerRadius)
    ]
  }));

  return !!filteredGroups.length ? (
    <StyledDistList className="bg-white p-1 rounded shadow">
      <button
        className="position-absolute d-flex align-items-center justify-content-center"
        style={{
          top: 10,
          zIndex: 1000,
          right: 10,
          outline: "none",
          cursor: "pointer",
          backgroundColor: "transparent",
          border: "none"
        }}
        onClick={() => setShowDistList(false)}
      >
        <FontAwesomeIcon icon={faTimes} />
      </button>

      {!!filteredGroups.length &&
        filteredGroups.map((g: any) => (
          <div key={g.id} className="mt-4 ml-2">
            <strong>{g.group_title.title}</strong> <br />
            {g.group_title.formatted_address}
            <div className="mt-2 position-relative">
              <div
                className="position-absolute d-flex align-items-center justify-content-center btn btn-outline-secondary"
                style={{
                  top: -35,
                  height: 25,
                  width: 25,
                  zIndex: 1000,
                  right: 0,
                  cursor: "pointer"
                }}
                onClick={() => handleExportCsv()}
              >
                <FontAwesomeIcon icon={faDownload} />
              </div>
              <table className="table-sm table-bordered table-hover border w-100">
                <thead>
                  <tr>
                    <th scope="col" style={{ textAlign: "left" }}>
                      Beschreibung
                    </th>
                    <th scope="col" style={{ textAlign: "left" }}>
                      Entfernung
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {g.distances.map((d: any) => (
                    <tr
                      key={d.title.title}
                      className={
                        currRowWithRoute === d.title.title
                          ? "table-primary"
                          : ""
                      }
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (currRowWithRoute !== d.title.title) {
                          setCurrRowWithRoute(d.title.title);
                          handleSetCoordsToShowRoute({
                            origin: d.location,
                            destination: g.group_title.location
                          });
                        } else {
                          setCurrRowWithRoute(null);
                          handleSetCoordsToShowRoute(null);
                        }
                      }}
                    >
                      <td
                        title={d.title.formatted_address}
                        style={{ color: d.color }}
                      >
                        {`${d.title.title} `}
                      </td>
                      <td>
                        {`${d.distance} km ${
                          d.shortDistance && d.shortDistance !== d.distance
                            ? ` (${d.shortDistance})`
                            : ""
                        }`}
                      </td>
                    </tr>
                  ))}
                  {!g.distances.length && (
                    <tr style={{ textAlign: "center", fontSize: "0.8rem" }}>
                      <td colSpan={2}>Keine Entfernungen</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        ))}
    </StyledDistList>
  ) : null;
};

export default DistList;
