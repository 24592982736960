import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { MouseEventHandler } from "react";

type OptionsBarProps = {
  handleOpenEdit: MouseEventHandler<SVGSVGElement>;
  handleDelete: () => void;
};

const OptionsBar: React.FC<OptionsBarProps> = ({
  handleOpenEdit,
  handleDelete
}) => {
  return (
    <>
      <FontAwesomeIcon
        icon={faPen}
        style={{
          position: "absolute",
          right: 40,
          top: "50%",
          transform: "translateY(-50%)",
          fontWeight: 500,
          fontSize: "1rem",
          cursor: "pointer"
        }}
        onClick={handleOpenEdit}
      />
      <FontAwesomeIcon
        icon={faTrash}
        style={{
          position: "absolute",
          right: 10,
          top: "50%",
          transform: "translateY(-50%)",
          fontWeight: 500,
          fontSize: "1rem",
          cursor: "pointer"
        }}
        onClick={handleDelete}
      />
    </>
  );
};

export default OptionsBar;
