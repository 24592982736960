import React, { useEffect } from "react";

const SearchMarker = ({
  text,
  zoom,
  type: { main, sub },
  maps,
  map,
  settlingRadius,
  location,
  ...rest
}: any) => {
  const textSize = zoom < 8 ? 0.7 : zoom > 12 ? 1 : 0.85;

  const color =
    main === "settling"
      ? { hex: "#0275d8", title: "blue" }
      : main === "competition"
      ? {
          hex: sub ? sub.color || "#dc3545" : "#dc3545",
          title: "red"
        }
      : { hex: "#28a745", title: "green" };

  useEffect(() => {
    const newCircle =
      main === "settling" || main === "competition"
        ? new maps.Circle({
            strokeColor: color.hex,
            strokeOpacity: 0.8,
            strokeWeight: 1,
            fillColor: color.hex,
            fillOpacity: 0.1,
            map,
            center: { lat: rest.lat, lng: rest.lng },
            radius: settlingRadius * 1000
          })
        : null;

    const newMarker = new maps.Marker({
      position: {
        lat: rest.lat,
        lng: rest.lng
      },
      map,

      icon: {
        url: `http://maps.google.com/mapfiles/ms/icons/${color.title}-dot.png`
      }
    });

    return () => {
      newMarker.setMap(null);
      if (newCircle) newCircle.setMap(null);
    };
  }, [settlingRadius, sub, location, map]);

  useEffect(() => {
    if (!location || !map) return;
    map.panTo(new maps.LatLng(location.location.lat, location.location.lng));
  }, [location, map]);

  return (
    <div className="position-relative" style={{ height: 30, width: 30 }}>
      <span
        style={{
          whiteSpace: "nowrap",
          fontSize: `${textSize}rem`,
          color: color.hex,
          fontWeight: 700
        }}
        className="ml-1"
      >
        {text}
      </span>
    </div>
  );
};

export default SearchMarker;
